<template>
  <div>
    <CategoryDropDown
      displayField="categoryName"
      valueField="id"
      ref="categoryDropDown"
      apiSource="categories/getlist"
      :isMultiple="true"
      :selectedValue="selectedCategories"
      :justUserCategories="true"
      @change="(item) => changed(item)"
      @binded="(item) => binded(item)"
    />
  </div>
</template>

<script>
import CheckBox from "../../widgets/form/CustomCheckBox";
import CategoryDropDown from "../../widgets/news/CategoryDropDown";
import { ClientTable } from "vue-tables-2";
export default {
  name: "CategoryList",
  props: {
    selectedCategories: { type: Array, required: false },
  },
  components: {
    CheckBox,
    ClientTable,
    CategoryDropDown,
  },
  methods: {
    changed(val) {
      const allCategoryList = this.$refs.categoryDropDown.list;
      this.categoryList = [];
      for (let i = 0; i < allCategoryList.length; i++) {
        let isChecked = false;
        if (val.filter((p) => p.id == allCategoryList[i].id).length > 0) {
          isChecked = true;
        }
        this.categoryList.push({
          ...allCategoryList[i],
          isChecked: isChecked,
        });
      }
      this.$emit("onCategoryChanged", this.categoryList);
    },
    binded(items) {
      if (this.selectedCategories.length == 0) {
        this.$emit("onCategoryChanged", this.$refs.categoryDropDown.list);
      }
    },
  },
  data() {
    return {
      categoryList: [],
      categoryIds: [],
    };
  },
  watch: {
    selectedCategories(val) {
      this.selectedCategories = val;
    },
  },
};
</script>
<style scoped>
fieldset.form-group {
  margin-bottom: 0rem !important;
}
</style>