<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      :cropIds="cropIds"
      @hide="editModal = false"
      :fileModel="selectedFile"
      :validHeight="editHeight"
      :validWidth="editWidth"
      @onSave="(base64) => saveAndUpload(base64)"
    >
    </PhotoEditorModal>
    <CustomModal
      :modalTitle="$t('alert')"
      :button1Title="$t('yes')"
      :button2Title="$t('no')"
      :show="uploadForAllAlertModal"
      @hide="uploadForAllAlertModal = false"
      @onButton1Click="uploadForAllStart()"
      @onButton2Click="uploadForAllStop()"
    >
      {{ $t("removeNewsFilesAlert") }}
    </CustomModal>
    <CustomModal
      :modalTitle="$t('alert')"
      :button1Title="$t('yes')"
      :button2Title="$t('no')"
      :show="removeAlertModal"
      @hide="removeAlertModal = false"
      @onButton1Click="removeForAll()"
      @onButton2Click="removeAlertModal = false"
    >
      {{ $t("removeNewsFilesAlert") }}
    </CustomModal>
    <b-row>
      <b-col sm="0"></b-col>
      <b-col sm="12" class="mb-2 text-center">
        <input
          type="file"
          accept="image/*"
          class="d-none"
          ref="uploadForAll"
          v-on:change="() => fileLoadedForAll()"
        />
        <b-button
          type="button"
          size="sm"
          @click="browseForAll"
          variant="success"
          ><i class="fa fa-folder-open"></i>
          {{ $t("browseForAllFiles") }}
        </b-button>
        <b-button
          type="button"
          class="ml-2"
          size="sm"
          @click="removeAlertModal = true"
          variant="danger"
          ><i class="fa fa-trash"></i>
          {{ $t("removeForAll") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="4"
        bg-variant="light"
        v-bind:key="fileType.id"
        v-for="(fileType, index) in fileTypeEntities"
        v-show="fileType.isShowing"
      >
        <div class="card bg-light text-center">
          <label for="uploadImage" class="index">
            <i :class="fileType.imageIcon"></i>
            {{ fileType.entityName }} {{ fileType.entitySize }}
            <i
              v-if="
                fileType.id == 29 ||
                fileType.id == 30 ||
                fileType.id == 31 ||
                fileType.id == 64 ||
                fileType.id == 12 ||
                fileType.id == 42 ||
                fileType.id == 43 ||
                fileType.id == 53
              "
              class="text-danger"
              >*</i
            ></label
          >
          <img
            class="card-img-top"
            ref="topImage"
            :src="
              fileType.localFile == null
                ? fileType.onlineFile.fileName
                : fileType.localFile
            "
            @click="showPreview(fileType)"
          />
          <div class="card-body">
            <div class="card-title pr-2 pl-2">
              <NormalTextBox
                prename="files"
                id="title"
                :withLabel="false"
                v-model="fileType.title"
                :value="fileType.title"
              />
            </div>
            <div class="card-text text-center pr-2 pl-2">
              <NormalTextArea
                prename="files"
                id="description"
                :rows="2"
                :withLabel="false"
                v-model="fileType.description"
                :value="fileType.description"
              />
              <b-form-group class="text-center">
                <input
                  type="file"
                  accept="image/*"
                  class="d-none"
                  ref="uploadFile"
                  v-on:change="() => fileLoaded(index)"
                />
                <b-button
                  type="button"
                  size="sm"
                  @click="browse(index)"
                  variant="success"
                  v-if="fileType.localFile == '/img/noPic.png'"
                  ><i class="fa fa-folder-open"></i>
                  {{ $t("browse") }}
                </b-button>
                <b-button
                  type="button"
                  size="sm"
                  v-if="
                    fileType.onlineFile != null &&
                    fileType.uploadPercentage == 0
                  "
                  @click="remove(index)"
                  variant="danger"
                >
                  <i class="fa fa-remove"></i>
                  {{ $t("delete") }}
                </b-button>
              </b-form-group>
              <b-progress
                :max="100"
                height="1rem"
                animated
                style="margin: 2px"
                v-if="fileType.uploadPercentage > 0"
              >
                <b-progress-bar
                  :value="fileType.uploadPercentage"
                  variant="success"
                >
                  <span
                    >{{ $t("loading") }}:
                    <strong
                      >{{ fileType.uploadPercentage.toFixed(0) }}%</strong
                    ></span
                  >
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NormalTextBox from "../../widgets/form/NormalTextBox";
import CustomModal from "../../widgets/form/CustomModal";
import NormalTextArea from "../../widgets/form/NormalTextArea";
import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
import { validationMixin } from "vuelidate";
import filesValidator from "../../validations/files-validator";
import toastHelper from "../../shared/toast-helper";
import { http } from "../../shared/http-base";
import httpGeneral from "../../shared/http";
import generalHelper from "../../shared/utils/general-helper";
import generatorsHelper from "../../shared/utils/generators-helper";
import { locales } from "moment";
export default {
  name: "NewsFiles",
  props: {
    selectedPositionIds: { type: Array },
    fileTypeEntities: { type: Array },
  },
  components: {
    NormalTextBox,
    NormalTextArea,
    PhotoEditorModal,
    CustomModal,
  },
  data() {
    return {
      uploadForAllAlertModal: false,
      removeAlertModal: false,
      entitySize: {
        42: "(100x100)",
        43: "(1080x1920)",
        12: "(526x395)",
        29: "(650x360)",
        64: "(246x246)",
        30: "(426x240)",
        53: "(1920x600)",
        31: "(1300x720)",
      },
      entityCropButtons: {
        42: 2,
        43: 2,
        12: 3,
        29: 4,
        30: 5,
        31: 4,
        64: 5,
        53: 11,
      },
      selectedFile: {},
      cropIds: null,
      editHeight: 0,
      editWidth: 0,
      editModal: false,
      //fileTypeEntities: [],
    };
  },
  methods: {
    isImageValid(file) {
      if (file.isShowing) {
        let validSize = this.$appSetting.fileTypeEntity.filter(
          (p) => p.id == file.id
        );
        if (validSize.length > 0) {
          validSize = validSize[0].cropSize;
          if (validSize[1] == file.height && validSize[0] == file.width)
            return true;
          else return false;
        } else {
          return true;
        }
      }
    },
    checkShowItems() {
      const MainPageFileId = 12;
      for (let i = 0; i < this.fileTypeEntities.length; i++) {
        let check =
          this.$appSetting.bindFileTypePosition[this.fileTypeEntities[i].id];
        // Append All Related position and category ids to check
        if (
          this.selectedPositionIds.length > 0 &&
          this.fileTypeEntities[i].id == MainPageFileId
        ) {
          this.selectedPositionIds.forEach((item) => {
            if (item.isCategory) check.push(item.id);
          });
        }
        // Append All Related position and category ids to check
        if (check !== undefined) {
          if (this.selectedPositionIds.length == 0) {
            this.fileTypeEntities[i].isShowing = false;
          } else {
            let state = false;
            check.forEach((element) => {
              let checkPosition = this.selectedPositionIds.filter(
                (p) => p.id == element
              );
              if (checkPosition.length > 0) {
                state = true;
              }
            });
            this.fileTypeEntities[i].isShowing = state;
          }
        } else {
          this.fileTypeEntities[i].isShowing = true;
        }
      }
    },
    showPreview(file) {
      if (file.onlineFile != null) {
        this.selectedFile = null;
        this.selectedFile = file;
        const validSize = this.$appSetting.fileTypeEntity.filter(
          (p) => p.id == file.id
        )[0].cropSize;
        this.editHeight = validSize[1];
        this.editWidth = validSize[0];
        this.cropIds = [];
        let checkButton = this.entityCropButtons[file.id];
        if (checkButton !== undefined) {
          this.cropIds.push(this.entityCropButtons[file.id]);
        } else {
          this.cropIds = Object.values(this.entityCropButtons);
          this.cropIds.push(8);
          this.cropIds.push(0);
          this.cropIds.push(1);
        }
        this.editModal = false;
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploadedYet"));
      }
    },
    // getFileTypeEntities() {
    //   httpGeneral.get(`Entities/getnewsfiletypeentities`).then((response) => {
    //     if (response) {
    //       var data = response;
    //       data.forEach((element) => {
    //         if (element.id != 9 && element.id != 10) {
    //           this.fileTypeEntities.push({
    //             title: "",
    //             description: "",
    //             id: element.id,
    //             entityName: element.entityName,
    //             entitySize: this.entitySize[element.id],
    //             localFile: "/img/noPic.png",
    //             onlineFile: null,
    //             uploadPercentage: 0,
    //             height: 0,
    //             width: 0,
    //             isShowing: true,
    //             imageIcon: "",
    //           });
    //         }
    //       });
    //       this.checkShowItems();
    //     }
    //   });
    // },
    browse(index) {
      this.$refs.uploadFile[index].click();
    },
    browseForAll() {
      this.$refs.uploadForAll.click();
    },
    remove(index, withMessage = true) {
      let obj = this.fileTypeEntities[index];
      if (obj.onlineFile != null) {
        let model = {
          fileId: obj.onlineFile.id,
        };
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineFile.id}`)
          .then((response) => {
            if (withMessage) toastHelper.success(response.message);
          });
      }
      obj.localFile = "/img/noPic.png";
      obj.onlineFile = null;
      obj.uploadPercentage = 0;
      this.fileTypeEntities[index] = obj;
      this.$refs.uploadFile[index].value = null;
      this.fileTypeEntities[index].imageIcon = "";
    },
    fileLoaded(index) {
      if (this.$refs.uploadFile[index] != null) {
        let file = this.$refs.uploadFile[index].files[0];

        if (generalHelper.isImageFile(file)) {
          this.fileTypeEntities[index].uploadPercentage = 1;
          this.fileTypeEntities[index].localFile = URL.createObjectURL(file);
          this.startUpload(index, null);
        } else {
          toastHelper.error(this.$t("fileIsNotValid"));
        }
      }
    },
    fileLoadedForAll() {
      if (this.$refs.uploadForAll != null) {
        let file = this.$refs.uploadForAll.files[0];
        if (generalHelper.isImageFile(file)) {
          let checkAvailableFiles = this.fileTypeEntities.filter(
            (p) => p.onlineFile != null
          );
          if (checkAvailableFiles.length > 0) {
            this.uploadForAllAlertModal = true;
          } else {
            this.uploadForAllStart();
          }
        } else {
          toastHelper.error(this.$t("fileIsNotValid"));
        }
      }
    },
    uploadForAllStart() {
      let junkFileIds = [];
      for (let i = 0; i < this.fileTypeEntities.length; i++) {
        if (this.fileTypeEntities[i].isShowing) {
          if (this.fileTypeEntities[i].onlineFile != null) {
            junkFileIds.push(this.fileTypeEntities[i].onlineFile.id);
          }
          this.fileTypeEntities[i].uploadPercentage = 99;
          this.fileTypeEntities[i].localFile = URL.createObjectURL(
            this.$refs.uploadForAll.files[0]
          );
          this.calculateImageSize(i);
        }
      }
      this.startUploadForAll();
      this.removeFiles(junkFileIds);
      this.uploadForAllStop();
    },
    uploadForAllStop() {
      this.uploadForAllAlertModal = false;
      this.fileForAll = null;
      this.$refs.uploadForAll.value = null;
    },
    removeFile(index) {
      this.fileTypeEntities[index].imageIcon = "";
      let obj = this.fileTypeEntities[index];
      if (obj.onlineFile != null) {
        let model = {
          fileId: obj.onlineFile.id,
        };
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineFile.id}`)
          .then((response) => {});
      }
    },
    removeFiles(fileIds) {
      let path = "Files/deletefilesbyid";
      http.post(path, fileIds).then((result) => {});
      this.$refs.uploadForAll.value = null;
    },
    removeForAll() {
      let idsToRemove = [];
      for (let i = 0; i < this.fileTypeEntities.length; i++) {
        this.fileTypeEntities[i].imageIcon = "";
        if (this.fileTypeEntities[i].onlineFile != null) {
          idsToRemove.push(this.fileTypeEntities[i].onlineFile.id);
        }
        this.fileTypeEntities[i].localFile = "/img/noPic.png";
        this.fileTypeEntities[i].onlineFile = null;
        this.fileTypeEntities[i].uploadPercentage = 0;
      }
      this.removeFiles(idsToRemove);
      this.removeAlertModal = false;
    },
    saveAndUpload(base64) {
      this.editModal = false;
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      let index = this.fileTypeEntities.indexOf(this.selectedFile);
      this.fileTypeEntities[index].localFile = base64;
      this.removeFile(index);
      this.startUpload(index, blob);
    },
    calculateImageSize(index = null) {
      if (this.fileTypeEntities === undefined) return;
      if (index != null) {
        //Load image size
        let img = new Image();
        img.onload = () => {
          this.fileTypeEntities[index].height = img.height;
          this.fileTypeEntities[index].width = img.width;
          let imageIconColor = "fa fa-times-circle text-danger";
          if (this.isImageValid(this.fileTypeEntities[index])) {
            imageIconColor = "fa fa-check-circle text-success";
          }
          this.fileTypeEntities[index].imageIcon = imageIconColor;
        };
        img.src =
          this.fileTypeEntities[index].localFile == null
            ? this.fileTypeEntities[index].onlineFile.fileName
            : this.fileTypeEntities[index].localFile;
        //Load image size
      } else {
        for (let i = 0; i < this.fileTypeEntities.length; i++) {
          //Load image size
          let img = new Image();
          img.onload = () => {
            this.fileTypeEntities[i].height = img.height;
            this.fileTypeEntities[i].width = img.width;
            let imageIconColor = "fa fa-times-circle text-danger";
            if (this.isImageValid(this.fileTypeEntities[i])) {
              imageIconColor = "fa fa-check-circle text-success";
            }
            this.fileTypeEntities[i].imageIcon = imageIconColor;
          };
          img.src =
            this.fileTypeEntities[i].localFile == null
              ? this.fileTypeEntities[i].onlineFile.fileName
              : this.fileTypeEntities[i].localFile;
          //Load image size
        }
      }
    },
    startUpload(index, blob) {
      let file = this.fileTypeEntities[index];
      this.calculateImageSize(index);
      if (file.localFile != "/img/noPic.png") {
        let formData = new FormData();
        if (blob != null) {
          formData.append("file", blob, "image.jpg");
        } else {
          formData.append("file", this.$refs.uploadFile[index].files[0]);
        }
        let self = this;
        http
          .post("/Files/upload", formData, {
            onUploadProgress: function (progressEvent) {
              this.fileTypeEntities[index].uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            file.onlineFile = response.data.data;
            file.uploadPercentage = 0;
          })
          .catch((e) => {
            if (blob == null) {
              self.remove(index);
            }
            if (!e.response.data.success) {
              toastHelper.error(e.response.data.message);
            }
          });
        this.fileTypeEntities[index] = file;
      } else {
        toastHelper.error($t("fileNotSelected"));
      }
    },
    startUploadForAll() {
      let file = this.$refs.uploadForAll.files[0];
      let formData = new FormData();
      formData.append("file", file);
      const fileTypeIds = this.fileTypeEntities
        .filter((p) => p.isShowing)
        .map((p) => p.id);
      let self = this;
      http
        .post("/Files/uploadwithfiletypeentityids", formData, {
          headers: {
            fileTypeEntityIdList: fileTypeIds,
          },
          // onUploadProgress: function (progressEvent) {
          //   this.fileTypeEntities[index].uploadPercentage = parseInt(
          //     Math.round((progressEvent.loaded / progressEvent.total) * 100)
          //   );
          // }.bind(this),
        })
        .then((response) => {
          for (let i = 0; i < this.fileTypeEntities.length; i++) {
            if (this.fileTypeEntities[i].isShowing) {
              const fileTypeId = this.fileTypeEntities[i].id;
              this.fileTypeEntities[i].uploadPercentage = 0;
              this.fileTypeEntities[i].onlineFile = response.data.data.filter(
                (p) => p.newsFileTypeEntityId == fileTypeId
              )[0].file;
            }
          }
        })
        .catch((e) => {
          self.removeForAll();
          if (!e.response.data.success) {
            toastHelper.error(e.response.data.message);
          }
        });
    },
  },
  mounted() {
    //this.getFileTypeEntities();
    if (this.fileTypeEntities.length) {
      this.fileTypeEntities.forEach((element) => {
        if (element.id != 9 && element.id != 10) {
          element.entitySize = this.entitySize[element.id];
        }
      });
    }
  },
  watch: {
    selectedPositionIds() {
      this.checkShowItems();
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-top: 8px;
  padding-bottom: 0px;
}
.prog {
  width: 100%;
}
.previewimage {
  cursor: pointer;
}
.animated .fadeIn .row div:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card-body {
  padding-left: 1px;
  padding-right: 1px;
}
.card-title fieldset {
  width: 100%;
  margin-bottom: 0;
}
.card-title {
  width: 100%;
  margin-bottom: 5;
}
.card-text {
  width: 100%;
}
.card-text fieldset {
  margin-bottom: 5px;
}
.index {
  background-color: white;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  opacity: 1;
  height: 30px;
  width: fit-content;
  padding: 10px;
  position: absolute;
  top: 5px;
  left: 0px;
  text-align: center;
  padding-top: 3px;
  border-color: #b6c2cc;
  border-width: 1px;
  border-style: solid;
  border-left-style: none;
}
.card-img-top {
  width: 100%;
  min-width: revert;
  height: 15vw;
  object-fit: contain;
  cursor: pointer;
}
</style>
