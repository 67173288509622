<template>
  <div class="animated fadeIn">
    <CustomModal
      :modalTitle="$t('alert')"
      :button1Title="$t('yes')"
      :button2Title="$t('no')"
      :show="historyAlertModal"
      @hide="hideHistoryAlert()"
      @onButton1Click="changeHistory()"
      @onButton2Click="hideHistoryAlert()"
    >
      {{ $t("historyAlert") }}
    </CustomModal>
    <CustomModal
      :modalTitle="$t('notificationDetails')"
      :button1Title="$t('ok')"
      :show="showNotificationModal"
      @hide="showNotificationModal = false"
      @onButton1Click="showNotificationModal = false"
    >
      <b-row>
        <b-col sm="12">
          <ValidationableTextBox
            prename="news"
            id="notificationTitle"
            icon="fa fa-font"
            v-model="$v.form.notificationTitle.$model"
            :form="$v.form.notificationTitle"
            :value="$v.form.notificationTitle"
          />
        </b-col>
        <b-col sm="12">
          <NormalTextArea
            prename="news"
            id="notificationDescription"
            icon="fa fa-font"
            :rows="2"
            :withLabel="true"
            v-model="$v.form.notificationDescription.$model"
          />
          <CustomCheckBox
            id="pushNotification"
            v-model="form.pushNotification"
            @input="(val) => (form.pushNotification = val)"
            customClass="label-switch-large"
            size="lg"
            :defaultValue="form.pushNotification"
            :labelIcon="{
              dataOn: $t('sendNotification'),
              dataOff: $t('dontSendNotification'),
            }"
          />
        </b-col>
      </b-row>
    </CustomModal>
    <b-row>
      <b-col sm="12" v-if="newsId != 0" v-show="showHistory" class="historyDiv">
        <b-form-group>
          <label for="newsHistory">{{ $t("newsHistory") }}</label>
          <NewsHistoryDropDown
            :apiSource="getNewsHistoryAddress()"
            :selectedValue="selectedHistoryId"
            displayField="createdAt"
            valueField="newsId"
            @loaded="
              (val) => {
                showHistory = val > 0;
              }
            "
            ref="historyList"
            :currentNewsId="newsId"
            :isMultiple="false"
            id="newsHistory"
            @change="(val) => newsHistoryChanged(val)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group>
          <label for="newsTypeEntity">{{ $t("newsTypeEntity") }}</label>
          <CustomDropDown
            apiSource="Entities/getnewstypeentities"
            :selectedValue="form.newsTypeEntityId"
            displayField="entityName"
            :firstText="$t('all')"
            valueField="id"
            :isMultiple="false"
            @change="(val) => newsTypeChanged(val)"
          />
        </b-form-group>
      </b-col>

      <b-col v-if="form.newsTypeEntityId === 52" cols="12" md="6">
        <b-form-group>
          <label for="authorId">{{ $t("author") }}</label>
          <CustomDropDown
            apiSource="authors/getlist"
            :selectedValue="form.authorId"
            displayField="nameSurename"
            :firstText="$t('selectAuthorTitle')"
            valueField="id"
            :isMultiple="false"
            @change="(val) => authorChanged(val)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group>
          <label for="publishDateTime">{{ $t("publishDateTime") }}</label>
          <VueCtkDateTimePicker
            v-model="form.publishDateTime"
            id="publishDateTime"
            :locale="getLocal()"
            noLabel
            :label="$t('dateTime')"
            inputSize="sm"
            color="firebrick"
            position="bottom"
            format="YYYY-MM-DD HH:mm"
            :minuteInterval="10"
            :button-now-translation="$t('now')"
          />
        </b-form-group>
      </b-col>

      <b-col v-if="form.newsTypeEntityId === 61" sm="12">
        <ValidationableTextBox
          prename="news"
          id="externalLink"
          icon="fa fa-link"
          :isRequired="true"
          v-model="$v.form.externalLink.$model"
          :form="$v.form.externalLink"
          :value="$v.form.externalLink"
        />
      </b-col>

      <b-col v-if="form.newsTypeEntityId !== 61" sm="12">
        <ValidationableTextBox
          prename="news"
          id="url"
          :showRemainChars="true"
          icon="fa fa-link"
          :isRequired="true"
          v-model="$v.form.url.$model"
          :form="$v.form.url"
          :value="$v.form.url"
          @keydown="(e) => onUrlKeyDown(e)"
        />
      </b-col>

      <b-col v-if="form.newsTypeEntityId !== 61" sm="12">
        <b-form-group>
          <label for="tagList">{{ $t("tags") }}</label>
          <CustomTagInput
            prename="news"
            icon="fa fa-tags"
            id="tagIds"
            @changed="(val) => (form.tagIds = val)"
            :defaultValues="loadTags"
            :form="$v.form.tagIds"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <label for="categories">{{ $t("categories") }}</label>
        <CategoryList
          id="categories"
          @onCategoryChanged="(categories) => onCategoryChanged(categories)"
          ref="newsCategories"
          :selectedCategories="selectedCategoriesList"
        />
      </b-col>
      <b-col sm="12 mt-3">
        <b-card>
          <div slot="header">{{ $t("newsPositions") }}</div>

          <NewsPositions
            :headingPositionEntityId="headingPositionEntityId"
            :selectedPositions="selectedPositions"
            ref="newsPositions"
            :orderValue="form.order"
            @onChanged="positionChanged"
          />
        </b-card>
      </b-col>
      <b-col sm="12" style="display: none">
        <b-card>
          <div slot="header">{{ $t("newsProperties") }}</div>
          <NewsProperties ref="newsProperties" />
        </b-card>
      </b-col>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="6" md="6" cols="6" xs="12" xl="6">
              <CustomCheckBox
                v-model="form.active"
                @input="(val) => (form.active = val)"
                :defaultValue="form.active"
                :label="$t('active')"
              />
            </b-col>
            <b-col sm="6" md="6" cols="6" xs="12" xl="6">
              <CustomCheckBox
                id="useTitle"
                v-model="form.useTitle"
                @input="(val) => (form.useTitle = val)"
                :defaultValue="form.useTitle"
                :label="$t('useTitle')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" md="6" cols="6" xs="12" xl="6">
              <b-button
                block
                variant="primary"
                ref="sendNotification"
                class="text-left"
                @click="showNotificationModal = true"
                ><i class="fa fa-bell"></i>
                {{ $t("notificationDetails") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import CustomDropDown from "../../widgets/form/CustomDropDown";
import CustomTagInput from "../../widgets/form/CustomTagInput";
import NewsPositions from "../../widgets/news/NewsPositions";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import NormalTextArea from "../../widgets/form/NormalTextArea";
import newsValidator from "../../validations/news-general-options-validator";
import CustomCheckBox from "../../widgets/form/CustomCheckBox";
import CategoryList from "../../widgets/news/CategoryList";
import stringHelper from "../../shared/string-helper";
import { validationMixin } from "vuelidate";
import NewsHistoryDropDown from "../../widgets/news/NewsHistoryDropDown";
import CustomModal from "../../widgets/form/CustomModal";
import NewsProperties from "../../widgets/news/NewsProperties";
export default {
  name: "GeneralOptions",
  props: {
    newsId: { type: Number, required: true },
    selectedPositions: { type: Array, required: false, default: null },
    selectedCategories: { type: Array, required: false },
  },
  components: {
    CustomDropDown,
    ValidationableTextBox,
    NormalTextArea,
    CategoryList,
    CustomTagInput,
    NewsPositions,
    CustomCheckBox,
    NewsHistoryDropDown,
    CustomModal,
    NewsProperties,
  },
  mixins: [validationMixin],
  validations: newsValidator.getValidations(),
  data() {
    return {
      showNotificationModal: false,
      selectedHistoryId: 0,
      loadTags: [],
      headingPositionEntityId: [],
      historyAlertModal: false,
      historyNewsId: 0,
      showHistory: false,
      selectedCategoriesList: [],
      form: {
        active: true,
        newsTypeEntityId: 2,
        newsAgencyEntityId: 26,
        publishDateTime: "",
        pushNotification: false,
        url: "",
        tagIds: [],
        authorId: null,
        externalLink: "",
        notificationTitle: "Ekonomist",
        notificationDescription: "",
        useTitle: true,
        order: 1,
      },
    };
  },
  methods: {
    positionChanged(positions) {
      this.$emit("onPositionChanged", positions);
    },
    getLocal() {
      return localStorage.lang;
    },
    onCategoryChanged(categories) {
      this.headingPositionEntityId = [];
      this.headingPositionEntityId = JSON.parse(JSON.stringify(categories));
    },
    onUrlLostFocus(e) {
      this.form.url = stringHelper.standardUrl(this.form.url);
    },
    onUrlKeyDown(e) {
      if (e.shiftKey || e.altKey || e.ctrlKey) e.returnValue = false;
      else e.returnValue = stringHelper.isValidNumChar(e.key);
    },
    hideHistoryAlert() {
      this.historyAlertModal = false;
      this.selectedHistoryId = null;
      this.historyNewsId = null;
    },
    changeHistory() {
      this.$router.push({ path: `/news-add/${this.historyNewsId}` });
      location.reload();
    },
    getNewsHistoryAddress() {
      return "News/gethistorybynewsid?newsId=" + this.newsId;
    },
    newsHistoryChanged(val) {
      if (val != null) {
        if (val.newsId != this.newsId) {
          this.historyNewsId = val.newsId;
          this.selectedHistoryId = val.newsId;
          this.historyAlertModal = true;
        }
      }
    },

    newsTypeChanged(val) {
      if (val && val.id) this.form.newsTypeEntityId = val.id;
    },
    authorChanged(val) {
      if (val != null) {
        this.form.authorId = val.id;
      } else {
        this.form.authorId = null;
      }
    },
  },
  watch: {
    selectedCategories(val) {
      const str = JSON.stringify(val).replaceAll("categoryId", "id");
      this.selectedCategoriesList = JSON.parse(str);
    },
    form: {
      handler(obj) {
        let standardUrl = stringHelper.standardUrl(obj.url);
        obj.url = standardUrl;
        this.$emit("onDataChanged", this.form);
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedCategoriesList = this.selectedCategories;
  },
};
</script>
<style>
.datetimepicker .datepicker {
  right: 20px !important;
}
</style>