<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      @hide="editModal = false"
      :fileModel="selectedFile"
      :validHeight="editHeight"
      :validWidth="editWidth"
      @onSave="(base64) => saveAndUpload(base64)"
    >
    </PhotoEditorModal>
    <b-row>
      <input
        type="file"
        class="d-none"
        ref="uploadFile"
        multiple
        accept="image/*"
        v-on:change="() => fileLoaded(0)"
      />
      <draggable v-model="uploadFilesList">
        <b-col
          sm="4"
          class="imageCard"
          v-bind:key="fileType.id"
          v-for="(fileType, index) in uploadFilesList"
        >
          <b-row>
            <b-col sm="12">
              <div class="card bg-light">
                <label :class="fileType.validState"
                  >{{ fileType.order }} ({{ validSize[0] }}x{{
                    validSize[1]
                  }})</label
                >
                <img
                  class="card-img-top"
                  :src="
                    fileType.localFile == null
                      ? fileType.onlineFile.fileName
                      : fileType.localFile
                  "
                  @click="showPreview(fileType)"
                />
                <div class="card-body">
                  <div class="card-title pl-2 pr-2">
                    <NormalTextBox
                      prename="files"
                      id="title"
                      :withLabel="false"
                      v-model="fileType.title"
                      :value="fileType.title"
                    />
                  </div>
                  <div class="card-text text-center pl-2 pr-2">
                    <NormalTextArea
                      prename="files"
                      id="description"
                      :rows="2"
                      :withLabel="false"
                      v-model="fileType.description"
                      :value="fileType.description"
                    />
                    <b-col sm="12">
                      <b-form-group>
                        <label for="tagList">{{ $t("tags") }}</label>
                        <CustomFileTagInput
                          prename="files"
                          icon="fa fa-tags"
                          id="fileTagIds"
                          @changed="(val) => (fileType.tagIds = val)"
                          :defaultValues="fileType.loadTags"
                        />
                      </b-form-group>
                    </b-col>
                    <div class="btn-group" role="group">
                      <b-button
                        type="button"
                        size="sm"
                        class="text-center"
                        style="margin-bottom: 2px"
                        @click="remove(index)"
                        variant="danger"
                      >
                        <i class="fa fa-remove"></i>
                        {{ $t("delete") }}
                      </b-button>
                    </div>
                    <b-progress
                      :max="100"
                      height="1rem"
                      animated
                      style="margin: 2px"
                      v-if="fileType.uploadPercentage > 0"
                    >
                      <b-progress-bar
                        :value="fileType.uploadPercentage"
                        variant="success"
                      >
                        <span
                          >{{ $t("loading") }}:
                          <strong
                            >{{ fileType.uploadPercentage.toFixed(0) }}%</strong
                          ></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </draggable>

      <b-col sm="12" class="float-right text-center">
        <b-button @click="browse(0)" class="btn btn-primary btn-lg mh-100">
          <i class="icon-cloud-upload h1" style="margin: 0px" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NormalTextBox from "../../widgets/form/NormalTextBox";
import NormalTextArea from "../../widgets/form/NormalTextArea";
import CustomModal from "../../widgets/form/CustomModal";
import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
import toastHelper from "../../shared/toast-helper";
import { http } from "../../shared/http-base";
import httpGeneral from "../../shared/http";
import generalHelper from "../../shared/utils/general-helper";
import draggable from "vuedraggable";
import CustomFileTagInput from '../../widgets/form/CustomFileTagInput.vue';
import generatorsHelper from "../../shared/utils/generators-helper";
export default {
  name: "NewsImageGallery",
  components: {
    NormalTextBox,
    NormalTextArea,
    CustomModal,
    draggable,
    PhotoEditorModal,
    CustomFileTagInput
  },
  watch: {
    uploadFilesList: {
      handler() {
        for (let i = 0; i < this.uploadFilesList.length; i++) {
          this.uploadFilesList[i].order = i + 1;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loadTags:[],
      selectedFile: {},
      editModal: false,
      editHeight: 0,
      validSize: this.$appSetting.fileTypeEntity.filter((p) => p.id == 9)[0]
        .cropSize,
      editWidth: 0,
      uploadFilesList: [],
    };
  },
  methods: {
    showPreview(file) {
      if (file.onlineFile != null) {
        this.selectedFile = null;
        this.selectedFile = file;
        const validSize = this.validSize;
        this.editHeight = validSize[1];
        this.editWidth = validSize[0];
        this.editModal = false;
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploaded"));
      }
    },
    addNewFile() {
      this.uploadFilesList.push({
        title: "",
        description: "",
        localFile: "/img/noPic.png",
        onlineFile: null,
        uploadPercentage: 0,
        validState: "index",
        tagIds : []
      });
    },
    browse(index) {
      this.$refs.uploadFile.click();
    },
    remove(index) {
      let obj = this.uploadFilesList[index];
      if (obj.onlineFile != null) {
        let model = {
          fileId: obj.onlineFile.id,
        };
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineFile.id}`)
          .then((response) => {
            toastHelper.success(response.message);
          });
      }
      this.uploadFilesList.splice(index, 1);
    },
    removeFile(index) {
      let obj = this.uploadFilesList[index];
      if (obj.onlineFile != null) {
        let model = {
          fileId: obj.onlineFile.id,
        };
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineFile.id}`)
          .then((response) => {});
      }
    },
    fileLoaded(index) {
      let files = this.$refs.uploadFile.files;
      let startIndex = 0;
      //control is there any previus files
      if (this.uploadFilesList.length > 0) {
        startIndex = this.uploadFilesList.length;
      }
      let file = files[0];
      let isFirstUploaded = false;
      if (generalHelper.isImageFile(file)) {
        this.addNewFile();
        this.uploadFilesList[startIndex].uploadPercentage = 1;
        this.uploadFilesList[startIndex].localFile = URL.createObjectURL(file);
        this.startUpload(startIndex, file);
        isFirstUploaded = true;
      } else {
        toastHelper.error(this.$t("fileIsNotValid"));
      }
      if (files.length > 1) {
        for (let i = 1; i < files.length; i++) {
          if (generalHelper.isImageFile(files[i])) {
            if (isFirstUploaded) startIndex++;
            isFirstUploaded = true;
            this.addNewFile();
            let lastIndex = this.uploadFilesList.length - 1;
            file = files[i];
            this.uploadFilesList[lastIndex].uploadPercentage = 1;
            this.uploadFilesList[lastIndex].localFile =
              URL.createObjectURL(file);
            var result = this.startUpload(startIndex, file);
          } else {
            toastHelper.error(this.$t("fileIsNotValid"));
          }
        }
      }
    },
    saveAndUpload(base64) {
      this.editModal = false;
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      let index = this.uploadFilesList.indexOf(this.selectedFile);
      this.uploadFilesList[index].localFile = base64;
      this.removeFile(index);
      this.startUpload(index, blob);
    },
    startUpload(index, fileInput) {
      let file = this.uploadFilesList[index];
      this.calculateImageSize(index);
      if (file.localFile != "/img/noPic.png") {
        let formData = new FormData();
        formData.append("file", fileInput, "image.jpg");
        let self = this;
        http
          .post("/Files/upload", formData, {
            onUploadProgress: function (progressEvent) {
              this.uploadFilesList[index].uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            file.onlineFile = response.data.data;
            file.uploadPercentage = 0;
          })
          .catch((e) => {
            self.remove(index);
            if (!e.response.data.success) {
              toastHelper.error(e.response.data.message);
            }
          });
        this.uploadFilesList[index] = file;
      } else {
        toastHelper.error($t("fileNotSelected"));
      }
    },
    isImageValid(file) {
      let validSize = this.$appSetting.fileTypeEntity.filter(
        (p) => p.id == 9
      )[0].cropSize;
      if (validSize) {
        if (validSize[1] == file.height && validSize[0] == file.width)
          return true;
        else return false;
      } else {
        return true;
      }
    },
    loadSize(index) {
      if (
        this.uploadFilesList[index].localFile != null ||
        this.uploadFilesList[index].onlineFile != null
      ) {
        let img = new Image();
        img.onload = () => {
          this.uploadFilesList[index].height = img.height;
          this.uploadFilesList[index].width = img.width;
          let imageIconColor = "bg-danger";
          if (this.isImageValid(this.uploadFilesList[index])) {
            imageIconColor = "bg-success";
          }
          this.uploadFilesList[index].validState = "index " + imageIconColor;
        };
        img.src =
          this.uploadFilesList[index].localFile == null
            ? this.uploadFilesList[index].onlineFile.fileName
            : this.uploadFilesList[index].localFile;
      }
    },
    calculateImageSize(index = null) {
      if (this.uploadFilesList === undefined) return;
      if (index != null) {
        //Load image size
        this.loadSize(index);
        //Load image size
      } else {
        for (let i = 0; i < this.uploadFilesList.length; i++) {
          //Load image size
          this.loadSize(i);
          //Load image size
        }
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-top: 8px;
  padding-bottom: 0px;
}
.prog {
  width: 100%;
}
.previewimage {
  cursor: pointer;
}
.animated .fadeIn .row div:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card-body {
  padding-left: 1px;
  padding-right: 1px;
}
.card-title fieldset {
  width: 100%;
  margin-bottom: 0;
}
.card-title {
  width: 100%;
  margin-bottom: 5;
}
.card-text {
  width: 100%;
}
.card-text fieldset {
  margin-bottom: 5px;
}
.index {
  background-color: white;
  border-radius: 5px;
  height: 30px;
  width: 90px;
  position: absolute;
  top: 5px;
  left: 5px;
  text-align: left;
  padding-top: 3px;
  padding-left: 7px;
  border-color: black;
  border: 1px;
  border-style: solid;
}
.card-img-top {
  width: 100%;
  min-width: revert;
  height: 15vw;
  object-fit: contain;
  cursor: pointer;
}
.imageCard {
  min-width: 15vw;
}
</style>