<template>
  <b-container>
    <div class="animated fadeIn">
      <b-form @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col sm="12">
            <v-server-table
              :columns="columns"
              :data="tagList"
              :options="options"
              id="dataTable"
              ref="dataTable"
              class="dataGridRowHeight"
            >
              <template slot="active" slot-scope="props">
                <CheckBox
                  v-model="props.row.isChecked"
                  @input="(val) => changed({ checkVal: val, row: props.row })"
                ></CheckBox>
              </template>
            </v-server-table>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
import CheckBox from "../../widgets/form/CustomCheckBox";
import gridOptions from "../../shared/grid-options";
import { ServerTable } from "vue-tables-2";
import jwtHelper from "../../shared/jwt-helper";
export default {
  name: "tagList",
  props: {},
  components: {
    CheckBox,
    ServerTable,
  },
  methods: {
    changed(val) {
      let isExists = this.tagList.indexOf(val);
    },
  },
  data() {
    return {
      tagList: [],
      currentProperies: [],
      orgList: [],
      userId: jwtHelper.getUserId(),
      columns: ["tagName", "active"],
      options: {
        ...gridOptions.getAll(),
        debounce: 100,
        headings: {
          title: this.$t("title"),
          active: this.$t("active"),
        },
        requestFunction: (data) => {
          let path = "Tags/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "createdAt";
            data.ascending = 0;
          }
          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {});
        },
      },
    };
  },
  created() {},
};
</script>