<template>
  <b-row>
    <b-col
      :sm="colSize"
      v-bind:key="property.id"
      v-for="property in propertiesList"
    >
      <CustomCheckBox
        v-model="property.isChecked"
        @input="(val) => changed(property)"
        :label="property.title"
        :defaultValue="property.isChecked"
      />
    </b-col>
  </b-row>
</template>
<script>
import http from "../../shared/http";
import CustomCheckBox from "../../widgets/form/CustomCheckBox";
export default {
  name: "NewsProperties",
  props: {
    colSize: { type: Number, default: 6 },
  },
  components: {
    CustomCheckBox,
  },
  data() {
    return {
      propertiesList: [],
    };
  },
  mounted() {
    this.getProperties();
  },
  computed: {
    propertyIds: {
      get() {
        let temp = [];
        this.propertiesList.forEach((item) => {
          if (item.isChecked) temp.push(item.id);
        });
        return temp;
      },
    },
  },
  methods: {
    getProperties() {
      let path = `Entities/getpropertyentities`;
      http.get(path).then((response) => {
        if (response) {
          var data = response;

          data.forEach((element) => {
            let temp = {
              id: element.id,
              title: element.entityName,
              isChecked: this.$route.params.id === undefined,
            };
            this.propertiesList.push(temp);
          });
        }
      });
    },
    changed(item) {},
  },
};
</script>