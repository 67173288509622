<template>
  <b-row>
    <b-col
      :sm="colSize"
      v-bind:key="position.id"
      v-for="position in positionsList"
      v-show="position.id !== 41"
    >
      <CustomCheckBox
        v-model="position.isChecked"
        @input="(val) => changed(position)"
        :label="position.title"
        :defaultValue="position.isChecked"
      />
    </b-col>
    <b-col sm="12" v-if="checkPosition()">
      <b-form-group>
        <label for="order">{{ $t("line") }}</label>
        <b-input-group style="width: 65px">
          <b-form-input
            :disabled="disableOrder"
            type="number"
            id="order"
            name="order"
            v-model="order"
            @input="
              (val) => {
                order = val;
                changed(val);
              }
            "
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import http from "../../shared/http";
import CustomCheckBox from "../../widgets/form/CustomCheckBox";

import NormalTextBox from "../../widgets/form/NormalTextBox";
export default {
  name: "NewsPositions",
  props: {
    orderValue: { required: true, type: Number, default: 1 },
    colSize: { type: Number, default: 6 },
    headingPositionEntityId: { type: Array, required: false },
    selectedPositions: { type: Array, default: null },
  },
  components: {
    CustomCheckBox,
    NormalTextBox,
  },
  data() {
    return {
      disableOrder: false,
      order: 1,
      positionsList: [],
      orgPositionList: [],
    };
  },
  mounted() {
    this.getPositions();
    var id = this.$route.params.id ? this.$route.params.id : 0;
    this.disableOrder = id > 0;
  },
  computed: {
    positionIds: {
      get() {
        let temp = [];
        this.positionsList.forEach((item) => {
          if (item.isChecked) temp.push(item.id);
        });
        return temp;
      },
    },
  },
  watch: {
    headingPositionEntityId() {
      this.filterData(this.orgPositionList);
    },
    orderValue(val) {
      this.order = val;
    },
  },
  methods: {
    checkPosition() {
      return (
        this.positionsList &&
        this.positionsList.length > 0 &&
        this.positionsList.some((p) => p.isChecked === true)
      );
    },
    filterData(data) {
      let tempPositions = this.positionsList;
      this.positionsList = [];
      data.forEach((element) => {
        let isChecked = false;
        if (this.selectedPositions.length > 0) {
          isChecked =
            this.selectedPositions.filter(
              (p) => p.positionEntityId == element.id
            ).length > 0;
        } else if (tempPositions.length > 0) {
          const check = tempPositions.filter((p) => p.id == element.id);
          if (check.length > 0) {
            isChecked = check[0].isChecked;
          }
        }
        const checkPosInCategory = this.headingPositionEntityId.filter(
          (p) => p.headingPositionEntityId == element.id
        );
        const checkIsMainPosition = this.$appSetting.MainPositions.filter(
          (p) => p.id == element.id
        );
        if (checkPosInCategory.length > 0) {
          if (checkPosInCategory[0].isChecked) {
            const temp = {
              id: element.id,
              title: element.entityName,
              isChecked: isChecked,
              isCategory: true,
            };
            this.positionsList.push(temp);
          }
        } else if (checkIsMainPosition.length != 0) {
          const temp = {
            id: element.id,
            title: element.entityName,
            isChecked: isChecked,
            isCategory: false,
          };
          this.positionsList.push(temp);
          this.changed(temp);
        }
      });
    },
    getPositions() {
      let path = `Entities/getpositionentities`;
      http.get(path).then((response) => {
        if (response) {
          var data = response;
          this.orgPositionList = data;
          this.filterData(data);
        }
      });
    },
    unCheckNoneMainPositions(item) {
      if (!item.isChecked) return;
      const mainPositions = this.$appSetting.MainPositions;
      mainPositions.forEach((pos) => {
        const CheckMainPosition = this.$appSetting.MainPositions.filter(
          (p) => p.id == item.id
        );
        if (CheckMainPosition.length > 0) {
          if (item.id != pos.id) {
            const posIndex = this.positionsList.findIndex(
              (p) => p.id == pos.id
            );
            if (posIndex != -1) {
              this.positionsList[posIndex].isChecked = false;
            }
          }
        }
      });
    },
    changed(item) {
      var pos = this.positionsList.filter((p) => p.isChecked);
      if (pos && pos.length > 0) {
        var ord = this.order;
        pos.forEach((p) => (p.order = ord));
      }
      this.$emit("onChanged", pos);
    },
  },
};
</script>