<template>
  <div>
    <b-input-group>
      <template #prepend>
        <b-input-group-text
          ><strong :class="iconColor"
            ><i :class="icon" v-if="icon != null" /></strong
        ></b-input-group-text>
      </template>
      <b-form-tags
        input-id="seoKeywords"
        v-model="keys"
        :value="keys"
        @input="(key) => changed(key)"
        separator=",;"
        :placeholder="$t('seoKeywords')"
        add-on-enter
        :state="validateState(id)"
        :addButtonText="$t('add')"
      />
      <b-form-invalid-feedback :id="id + '-feedback'">{{
        $t(`${prename}Validator.${id}`)
      }}</b-form-invalid-feedback>
    </b-input-group>
  </div>
</template>
<script>
export default {
  name: "CustomTagInputValidation",
  props: {
    id: { required: true, type: String },
    prename: { required: false, type: String, default: "" },
    keyWords: { type: Array },
    form: { required: true, type: Object },
    icon: { type: String, default: null },
    iconColor: { type: String, default: "text-dark" },
  },
  watch: {
    keyWords() {
      this.keys = this.keyWords;
    },
  },
  data() {
    return {
      keys: this.keyWords,
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.form;
      return $dirty ? !$error : null;
    },
    changed(val) {
      this.$emit("changed", val);
    },
  },
};
</script>