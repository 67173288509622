<template>
  <div class="animated">
    <b-row>
      <b-col sm="8">
        <b-row>
          <b-col sm="4">
            <b-form-group>
              <label for="newsAgencyEntityId">{{
                $t("newsAgencyEntity")
              }}</label>
              <EntitiesDropdown
                :placeholder="$t('newsAgencyEntity')"
                endPoint="getnewsagencyentities"
                :entityId="filters.newsAgencyEntityId"
                :includeall="true"
                @change="(val) => (filters.newsAgencyEntityId = val.id)"
              ></EntitiesDropdown>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group>
              <label for="newsTypeEntityId">{{ $t("newsTypeEntity") }}</label>
              <EntitiesDropdown
                :placeholder="$t('newsTypeEntity')"
                endPoint="getnewstypeentities"
                :entityId="filters.newsTypeEntityId"
                :includeall="true"
                @change="(val) => (filters.newsTypeEntityId = val.id)"
              ></EntitiesDropdown>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <v-server-table
              :columns="columns"
              ref="table"
              :options="options"
              id="dataTable"
              class="dataGrid"
            >
              <template slot="thumbnail" slot-scope="props">
                <b-img
                  :src="
                    props.row.thumbnail == null
                      ? '/img/noPic.png'
                      : props.row.thumbnail
                  "
                  fluid
                  height="40"
                  width="40"
                  thumbnail
                />
              </template>
              <template slot="buttons" slot-scope="props">
                <CustomCheckBox
                  v-model="props.row.isChecked"
                  :defaultValue="getState(props.row)"
                  @input="(val) => changed({ state: val, model: props.row })"
                  v-bind:key="props.row.id"
                />
              </template>
            </v-server-table>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="4">
        <b-row>
          <b-col sm="12">
            <b-row style="margin-top: 32px">
              <b-col sm="12">
                <b-button
                  block
                  @click="clearList"
                  variant="danger"
                  type="button"
                  class="text-center mt-0"
                  ><i class="fa fa-trash"></i>
                  {{ $t("clearList") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row style="margin-top: 18px">
              <b-col sm="12">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle.selectedRelatedNewsList
                      variant="default"
                      >{{ $t("selectedRelatedNewsList") }}</b-button
                    >
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col sm="12">
                          <b-list-group>
                            <b-list-group-item
                              v-bind:key="news.id"
                              v-for="news in newsList"
                            >
                              <b-img
                                :src="
                                  news.thumbnail == null
                                    ? '/img/noPic.png'
                                    : news.thumbnail
                                "
                                fluid
                                thumbnail
                                height="40"
                                width="40"
                              />
                              <label
                                class="news-title"
                                @click="gotoNews(news.url)"
                              >
                                {{ news.title }}</label
                              >

                              <i
                                class="fa fa-remove text-danger pull-right"
                                style="margin-top: 4px; cursor: pointer"
                                @click="removeFromList(news)"
                              ></i>
                            </b-list-group-item>
                          </b-list-group>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import dateHelper from "../../shared/date-helper";
import utility from "../../shared/utils/screen-helper";
import toast from "../../shared/toast-helper";
import CustomModal from "../../widgets/form/CustomModal";
import CustomCheckBox from "../../widgets/form/CustomCheckBox";
import ActiveStateDropDown from "../../widgets/ActiveStateDropDown";
import BooleanStateDropDown from "../../widgets/BooleanStateDropDown";
import EntitiesDropdown from "../../widgets/EntitiesDropdown";
export default {
  name: "RelatedNews",
  props: {
    relatedModel: { type: Array },
    currentNewsId: { type: Number, default: 0 },
  },
  components: {
    ServerTable,
    CustomModal,
    CustomCheckBox,
    ActiveStateDropDown,
    BooleanStateDropDown,
    EntitiesDropdown,
  },
  methods: {
    getList() {
      this.$refs.table.setPage(1);
    },
    gotoNews(url) {
      window.open(process.env.VUE_APP_UI_URL + url, "_blank");
    },
    clearList() {
      this.newsList.splice(0, this.newsList.length);
      this.loadedModel.splice(0, this.loadedModel.length);
    },
    removeFromList(news) {
      let check = this.newsList.filter((p) => p.id == news.id);
      if (check.length > 0) {
        let index = this.newsList.indexOf(check[0]);
        this.newsList.splice(index, 1);
        this.loadedModel.splice(index, 1);
      }
    },
    getState(row) {
      let check = this.newsList.filter((p) => p.id == row.id);
      let checkLoad = 0;
      // if (this.loadedModel != null) {
      //   checkLoad = this.loadedModel.filter((p) => p.relatedNewsId == row.id).length;
      // }
      if (check.length > 0 || checkLoad) {
        row.isChecked = true;
      } else {
        row.isChecked = false;
      }
      return row.isChecked;
    },
    addToList(model) {
      let check = this.newsList.filter((p) => p.id == model.id);
      if (check.length == 0) {
        this.newsList.push(model);
      }
    },
    changed(val) {
      let check = this.loadedModel.findIndex(
        (p) => p.relatedNewsId == val.model.id
      );
      if (check > 0) {
        val.state = true;
      }
      if (val.state) {
        this.addToList(val.model);
      } else {
        this.removeFromList(val.model);
      }
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(function () {
      let filterDiv = this.$el.getElementsByClassName("dataGrid")[0]
        .childNodes[0].childNodes[0].childNodes[0];
      filterDiv.parentElement.setAttribute("class", "col-sm-4");

      filterDiv.childNodes[0].setAttribute("class", "");
      filterDiv.childNodes[0].childNodes[0].setAttribute("class", "text-left");

      let filterLabel = filterDiv.childNodes[0].childNodes[0];
      let filterTextbox = filterDiv.childNodes[0].childNodes[1];
      let filterTableDiv = filterDiv.childNodes[0].parentElement;
      filterTableDiv.appendChild(filterLabel);
      filterTableDiv.appendChild(filterTextbox);
      filterTableDiv.setAttribute("class", "form-group");

      let filter =
        filterDiv.parentElement.parentElement.parentElement.parentElement
          .parentElement.parentElement.firstChild;
      let lenght = filter.childNodes.length;
      for (let i = 0; i < lenght; i++) {
        filterDiv.parentElement.parentElement.appendChild(filter.childNodes[0]);
      }

      this.loadedModel = JSON.parse(JSON.stringify(this.relatedModel));
      this.newsList = [];
      this.relatedModel.forEach((item) => {
        this.newsList.push(item.relatedNews);
      });
    });
  },
  data() {
    return {
      loadedModel: [],
      newsList: [],
      filters: {
        active: null,
        isDraft: null,
        newsAgencyEntityId: null,
        newsTypeEntityId: null,
        approved: null,
      },
      columns: ["thumbnail", "title", "shortDescription", "buttons"],
      options: {
        ...gridOptions.getAll(),
        requestFunction: (data) => {
          let path = "News/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "createdAt";
            data.ascending = 0;
          }
          if (this.filters) {
            data.newsAgencyEntityId = this.filters.newsAgencyEntityId;
            data.newsTypeEntityId = this.filters.newsTypeEntityId;
          }

          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {
              //utility.scrollToTop();
            });
        },
        responseAdapter: (response) => {
          if (this.currentNewsId > 0) {
            response.data = response.data.filter(
              (p) => p.id != this.currentNewsId
            );
          }
          return response;
        },
        sortable: "title,shortDescription,active,approve,isDraft",
        headings: {
          thumbnail: this.$t("thumbnail"),
          title: this.$t("title"),
          shortDescription: this.$t("shortDescription"),
          buttons: this.$t("active"),
        },
        templates: {
          createdAt: (h, row) =>
            moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>
<style>
td fieldset {
  margin-bottom: 0px !important;
}
.VueTables__limit {
  display: none;
}
.list-group-item {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  padding: 5px;
}
.news-title {
  margin: 0px 0px 0px 3px;
  cursor: pointer;
}
</style>