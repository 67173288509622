<template>
  <div class="animated fadeIn">
    <CustomModal
      :modalTitle="$t('alert')"
      :button1Title="$t('yes')"
      :button2Title="$t('no')"
      button1Icon=""
      button2Icon=""
      :show="exitModal"
      @hide="
        () => {
          exitModal = false;
          exitDestination = null;
        }
      "
      @onButton1Click="exit()"
      @onButton2Click="
        () => {
          exitModal = false;
          exitDestination = null;
        }
      "
    >
      {{ $t("newsExitConfirm") }}
    </CustomModal>

    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="8">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.generalInformation
                variant="default"
                ref="generalInformation"
                class="text-left"
                @click="collapseIcon('generalInformation')"
                ><i class="fa fa-gear text-primary"></i>
                {{ $t("generalInformation") }}

                <span class="float-right">
                  <i class="fa fa-arrow-up"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="generalInformation"
              accordion="my-accordion"
              role="tabpanel"
              visible
            >
              <b-card-body>
                <b-row>
                  <!-- <b-col sm="12">
                    <ValidationableTextBox
                      prename="news"
                      id="subTitle"
                      icon="fa fa-font"
                      :showRemainChars="true"
                      :isRequired="true"
                      v-model="$v.form.subTitle.$model"
                      :form="$v.form.subTitle"
                      :value="$v.form.subTitle"
                    />
                  </b-col> -->
                  <b-col sm="12">
                    <ValidationableTextBox
                      prename="news"
                      id="newsTitleField"
                      icon="fa fa-font"
                      :showRemainChars="true"
                      :isRequired="true"
                      v-model="$v.form.title.$model"
                      :form="$v.form.title"
                      :value="$v.form.title"
                      @onLostFocus="onTitleLostFocus"
                    />
                  </b-col>
                  <b-col
                    v-show="this.generalOptionsModel.newsTypeEntityId !== 61"
                    sm="12"
                  >
                    <ValidationableTextBox
                      prename="news"
                      id="innerTitle"
                      icon="fa fa-font"
                      :showRemainChars="true"
                      :isRequired="true"
                      v-model="$v.form.innerTitle.$model"
                      :form="$v.form.innerTitle"
                      :value="$v.form.innerTitle"
                      @onLostFocus="onInnerTitleLostFocus"
                    />
                  </b-col>
                  <b-col
                    v-show="this.generalOptionsModel.newsTypeEntityId !== 61"
                    sm="12"
                  >
                    <ValidationableTextArea
                      prename="news"
                      :isRequired="true"
                      :showRemainChars="true"
                      id="shortDescription"
                      v-model="$v.form.shortDescription.$model"
                      :form="$v.form.shortDescription"
                      :value="$v.form.shortDescription"
                      @onLostFocus="onShortDescriptionLostFocus()"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-show="this.generalOptionsModel.newsTypeEntityId !== 61"
                    sm="12"
                  >
                    <TextEditor
                      v-if="!isInitializing"
                      :content="form.htmlContent"
                      @input="(val) => (form.htmlContent = val)"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-show="this.generalOptionsModel.newsTypeEntityId !== 61"
            no-body
            class="mb-1"
          >
            <b-card-header v-once header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.seoInformation
                class="text-left"
                variant="default"
                ref="seoInformation"
                @click="collapseIcon('seoInformation')"
                ><i class="fa text-primary fa-hashtag"></i>
                {{ $t("seoInformation") }}

                <span class="float-right">
                  <i class="fa fa-arrow-down"></i> </span
              ></b-button>
            </b-card-header>
            <b-collapse
              id="seoInformation"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-row>
                  <b-col sm="6">
                    <ValidationableTextBox
                      prename="news"
                      id="seoTitle"
                      :showRemainChars="true"
                      icon="fa fa-font"
                      v-model="$v.form.seoTitle.$model"
                      :form="$v.form.seoTitle"
                    />
                  </b-col>
                  <b-col sm="6">
                    <label for="seoKeywords">{{ $t("seoKeywords") }} </label>
                    <CustomTagInputValidation
                      v-model="keyWords"
                      :keyWords="keyWords"
                      icon="fa fa-tags"
                      :form="$v.form.seoKeywords"
                      id="seoKeywords"
                      prename="news"
                      @changed="(val) => seoKeywordsChanged(val)"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12">
                    <ValidationableTextArea
                      prename="news"
                      :showRemainChars="true"
                      icon="fa fa-file"
                      :isRequired="true"
                      id="seoDescription"
                      v-model="$v.form.seoDescription.$model"
                      :form="$v.form.seoDescription"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <ValidationableTextBox
                      prename="news"
                      :showRemainChars="true"
                      id="socialTitle"
                      icon="fa fa-font"
                      v-model="$v.form.socialTitle.$model"
                      :form="$v.form.socialTitle"
                    />
                  </b-col>
                  <b-col sm="12">
                    <ValidationableTextArea
                      prename="news"
                      :showRemainChars="true"
                      icon="fa fa-file"
                      id="socialDescription"
                      v-model="$v.form.socialDescription.$model"
                      :form="$v.form.socialDescription"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header v-once header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.newsFiles
                class="text-left"
                variant="default"
                ref="files"
                @click="collapseIcon('files')"
              >
                <i class="fa text-primary fa-file"></i>
                {{ $t("files") }}

                <span class="float-right">
                  <i class="fa fa-arrow-down"></i> </span
              ></b-button>
            </b-card-header>
            <b-collapse id="newsFiles" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-row>
                  <b-col sm="12" v-if="fileTypeEntities.length">
                    <NewsFiles
                      ref="newsFiles"
                      :selectedPositionIds="positionsList"
                      :fileTypeEntities="fileTypeEntities"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-show="this.generalOptionsModel.newsTypeEntityId !== 61"
            no-body
            class="mb-1"
          >
            <b-card-header v-once header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.relatedNewsInfo
                class="text-left"
                variant="default"
                data-isRelatedNews="yes"
                ref="relatedNewsInformation"
                @click="collapseIcon('relatedNewsInformation')"
              >
                <i class="fa text-primary fa-link"></i>
                {{ $t("relatedNewsInformation") }}

                <span class="float-right">
                  <i class="fa fa-arrow-down"></i> </span
              ></b-button>
            </b-card-header>
            <b-collapse
              id="relatedNewsInfo"
              accordion="my-accordion"
              role="tabpanel"
              v-if="loadRelatedNews"
            >
              <b-card-body>
                <b-row>
                  <b-col sm="12">
                    <RelatedNews
                      :relatedModel="news.newsRelatedNewsList"
                      :currentNewsId="newsId"
                      ref="relatedNews"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            no-body
            class="mb-1"
            v-show="this.generalOptionsModel.newsTypeEntityId == 4"
          >
            <b-card-header v-once header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.imageGalleryInformation
                variant="default"
                ref="imageGalleryInformation"
                @click="collapseIcon('imageGalleryInformation')"
                class="text-left"
                ><i class="fa text-primary fa-image"></i>
                {{ $t("imageGalleryInformation") }}

                <span class="float-right">
                  <i class="fa fa-arrow-down"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="imageGalleryInformation"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <NewsImageGallery ref="newsImageGallery" />
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card
            no-body
            class="mb-1"
            v-show="
              this.generalOptionsModel.newsTypeEntityId == 3 ||
              positionsList.some((f) => f.id === 74)
            "
          >
            <b-card-header v-once header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.videoGalleryInformation
                variant="default"
                class="text-left"
                ref="videoGalleryInformation"
                @click="collapseIcon('videoGalleryInformation')"
                ><i class="fa text-primary fa-camera"></i>
                {{ $t("videoGalleryInformation") }}
                <span class="float-right">
                  <i class="fa fa-arrow-down"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="videoGalleryInformation"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <NewsVideoGallery ref="newsVideoGallery" />
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-col>
        <b-col sm="4">
          <div class="lock-user" v-if="lockUser">({{ lockUser }})</div>
          <div style="display: flex">
            <b-button
              block
              variant="success"
              type="button"
              v-if="newsId > 0 && (!lockUserId || lockUserId !== currentUserId)"
              @click="lockNews(true)"
              class="text-center mt-0 publish-button"
              ><i class="text-success fa fa-lock"></i>
              {{ $t("lockNews") }}
            </b-button>
            <b-button
              block
              variant="primary"
              type="button"
              v-if="newsId > 0 && lockUserId && lockUserId === currentUserId"
              @click="lockNews(false)"
              class="btn btn-danger"
              ><i class="fa fa-unlock"></i>
              {{ $t("unlockNews") }}
            </b-button>
            <b-button
              :disabled="
                newsId > 0 && lockUserId && lockUserId !== currentUserId
              "
              block
              variant="dark"
              type="button"
              @click="dataCollector('draft')"
              v-show="news.isDraft"
              :class="getDraftClass()"
              ><i class="fa fa-file"></i>
              {{ $t("saveDraft") }}
            </b-button>
            <b-button
              :disabled="
                newsId > 0 && lockUserId && lockUserId !== currentUserId
              "
              block
              variant="primary"
              type="button"
              @click="dataCollector('draft', true)"
              :class="getPreviewClass()"
              ><i class="fa fa-link"></i>
              {{ $t("saveOnizle") }}
            </b-button>
            <b-button
              :disabled="
                newsId > 0 && lockUserId && lockUserId !== currentUserId
              "
              block
              variant="success"
              type="button"
              @click="dataCollector('publish')"
              class="text-center mt-0 publish-button"
              ><i class="fa fa-save"></i>
              {{ $t("savePublish") }}
            </b-button>
          </div>
          <b-card class="mt-2" v-if="!isInitializing">
            <div slot="header" class="text-center">
              <i class="fa fa-gear"></i> {{ $t("generalOptions") }}
            </div>
            <GeneralOptions
              @onDataChanged="(model) => generalOptionsChanged(model)"
              @onPositionChanged="positionChanged"
              ref="generalOptions"
              :orderValue="news.newsPositionOrder"
              :headingPositionEntityId="headingPositionEntityId"
              :selectedPositions="news.newsPositionList"
              :selectedCategories="news.newsCategoryList"
              :newsId="newsId"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import http from "../../shared/http";
import newsValidator from "../../validations/news-main-validator";
import toast from "../../shared/toast-helper";
import jwtHelper from "../../shared/jwt-helper";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import ValidationableTextArea from "../../widgets/form/ValidationableTextArea";
import CustomDatePicker from "../../widgets/form/CustomDatePicker";
import EntitiesDropdown from "../../widgets/EntitiesDropdown";
import { validationMixin } from "vuelidate";
import CustomModal from "../../widgets/form/CustomModal";
import PropertyList from "../../widgets/news/PropertyList";
import TagList from "../../widgets/news/TagList";
import GeneralOptions from "../../widgets/news/GeneralOptions";
import NewsFiles from "../../widgets/news/NewsFiles";
import RelatedNews from "../../widgets/news/RelatedNews";
import NewsImageGallery from "../../widgets/news/NewsImageGallery";
import NewsVideoGallery from "../../widgets/news/NewsVideoGallery";
import CustomTagInputValidation from "../../widgets/form/CustomTagInputValidation";
import generalHelper from "../../shared/utils/general-helper";
import toastHelper from "../../shared/toast-helper";
import dateHelper from "../../shared/date-helper";
export default {
  name: "NewsAdd",
  components: {
    ValidationableTextArea,
    ValidationableTextBox,
    EntitiesDropdown,
    CustomTagInputValidation,
    CustomDatePicker,
    PropertyList,
    TagList,
    GeneralOptions,
    NewsFiles,
    RelatedNews,
    CustomModal,
    NewsImageGallery,
    NewsVideoGallery,
  },
  mixins: [validationMixin],
  validations: newsValidator.getValidations(),
  data() {
    return {
      lockUser: null,
      lockUserId: null,
      currentUserId: jwtHelper.getUserId(),
      fileTypeEntities: [],
      newsLocked: false,
      loadRelatedNews: false,
      newsId: 0,
      firstCategoryTitle: "",
      isAfterSave: false,
      isInitializing: true,
      exitModal: false,
      positionsList: [],
      exitDestination: null,
      headingPositionEntityId: [],
      keyWords: [],
      generalOptionsModel: {
        newsTypeEntityId: 0,
        url: "",
        tagIds: [],
        externalLink: "",
      },
      form: {
        userId: 0,
        newsTypeEntityId: 0,
        newsAgencyEntityId: 0,
        title: "",
        innerTitle: "",
        subTitle:"",
        shortDescription: "",
        htmlContent: "",
        socialTitle: "",
        socialDescription: "",
        seoTitle: "",
        seoKeywords: "",
        seoDescription: "",
        url: "",
        publishDate: "",
        publishTime: {},
        publishDateTime: "",
        historyCode: "",
        isDraft: true,
        newsCategoryList: [],
        newsFileList: [],
        newsPositionList: [],
        newsPropertyList: [],
        newsRelatedNewsList: [],
        newsTagList: [],
        externalLink: "",
        pushNotification: false,
        notificationDescription: "",
        notificationTitle: "",
        useTitle: true,
        newsPositionOrder: 0,
      },
      news: {
        newsPositionOrder: 0,
        newsId: 0,
        userId: 0,
        newsTypeEntityId: 0,
        newsAgencyEntityId: 0,
        title: "",
        innerTitle: "",
        subtitle:"",
        shortDescription: "",
        htmlContent: "",
        socialTitle: "",
        socialDescription: "",
        seoTitle: "",
        seoKeywords: "",
        seoDescription: "",
        url: "",
        publishDate: "",
        publishTime: "",
        historyCode: "",
        pushNotification: false,
        isDraft: true,
        active: true,
        newsCategoryList: [],
        newsFileList: [],
        newsPositionList: [],
        newsPropertyList: [],
        newsRelatedNewsList: [],
        newsTagList: [],
        externalLink: "",
        authorId: null,
        notificationDescription: "",
        notificationTitle: "",
        useTitle: true,
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.exitDestination == null && !this.isAfterSave && !this.newsLocked) {
      this.exitModal = true;
      this.exitDestination = to.path;
      next(false);
    } else {
      next();
    }
  },
  methods: {
    lockNews(lockNews) {
      http
        .post(
          `News/locknews`,
          { newsId: this.newsId, lockNews: lockNews },
          true
        )
        .then((response) => {
          toastHelper.success(response.message);
          if (lockNews) {
            this.lockUser = jwtHelper.getNameSurname();
            this.lockUserId = this.currentUserId;
          } else {
            this.lockUser = null;
            this.lockUserId = null;
          }
        })
        .catch(() => {});
    },
    getFileTypeEntities() {
      http
        .get(`Entities/getnewsfiletypeentities`)
        .then((data) => {
          if (data) {
            data.forEach((element) => {
              if (element.id != 9 && element.id != 10) {
                this.fileTypeEntities.push({
                  title: "",
                  description: "",
                  id: element.id,
                  entityName: element.entityName,
                  //entitySize: this.entitySize[element.id],
                  localFile: "/img/noPic.png",
                  onlineFile: null,
                  uploadPercentage: 0,
                  height: 0,
                  width: 0,
                  isShowing: true,
                  imageIcon: "",
                });
              }
            });
          }
          this.newsId = this.$route.params.id ? this.$route.params.id : 0;
          this.newsId = parseInt(this.newsId);
          if (this.newsId > 0) this.getNewsInformation();
          else {
            this.isInitializing = false;
            this.news.newsPositionOrder = 1;
          }
        })
        .catch((e) => {
          console.log(e);
          this.$router.push({ path: "/news-list" });
        });
    },
    getNewsPositionOrder() {
      var ord = 1;
      if (!this.news.newsPositionList || this.news.newsPositionList.length == 0)
        return ord;
      var firstNotZeroValue = this.news.newsPositionList.filter(
        (f) => f.order > 0
      );
      if (firstNotZeroValue && firstNotZeroValue.length > 0)
        ord = firstNotZeroValue[0].order;

      return ord;
    },
    positionChanged(positions) {
      this.positionsList = positions;
    },
    onShortDescriptionLostFocus() {
      if (this.form.seoDescription == "") {
        this.form.seoDescription = this.form.shortDescription;
      }
      if (this.form.socialDescription == "") {
        this.form.socialDescription = this.form.shortDescription;
      }
    },
    onInnerTitleLostFocus() {
      if (this.form.seoTitle == "") {
        this.form.seoTitle = this.form.innerTitle;
      }
      if (this.form.socialTitle == "") {
        this.form.socialTitle = this.form.innerTitle;
      }
    },
    getLocal() {
      return localStorage.lang;
    },
    getDraftClass() {
      if (this.news.isDraft) {
        return "text-center mt-0 draft-button";
      }
    },
    getPreviewClass() {
      if (this.news.isDraft) {
        return "text-center mt-0 preview-button";
      } else {
        return "text-center mt-0 draft-button";
      }
    },
    exit() {
      //this.$router.push({ path: `${this.exitDestination}` });

      let address = window.location.href.substr(
        0,
        window.location.href.indexOf("/news-add")
      );
      window.location.href = address + this.exitDestination;
      window.location.reload();
    },
    seoKeywordsChanged(val) {
      this.keyWords = val;
    },
    dataCollector(type, isPreview = false) {
      this.news.newsFileList = this.news.newsFileList.splice(
        0,
        this.news.newsFileList.length
      );
      // this.news.newsCategoryList = this.news.newsCategoryList.splice(
      //   0,
      //   this.news.newsCategoryList.length
      // );
      let generalSection = this.$refs.generalOptions;
      if (this.generalOptionsModel.newsTypeEntityId == 61) {
        // external link
        if (generalSection.$v.form.externalLink.$anyError) {
          toastHelper.error(this.$t("newsExternalLinkError"));
          return false;
        }

        this.form.innerTitle = this.form.title;
        this.form.subTitle = this.form.subTitle;
        this.form.shortDescription = this.form.title;
        this.form.htmlContent = this.form.title;
        this.form.socialTitle = this.form.title;
        this.form.socialDescription = this.form.title;

        this.form.seoTitle = this.form.title;
        this.form.url = this.form.title.replaceAll(" ", "-");
        this.form.socialDescription = this.form.title;
      }
      generalSection.$v.form.$touch();
      var positions = generalSection.$refs.newsPositions.positionIds;
      if (
        positions &&
        this.generalOptionsModel.newsTypeEntityId !== 2 &&
        (positions.indexOf(51) !== -1 || positions.indexOf(50) !== -1)
      ) {
        toastHelper.error(this.$t("newsTypeAndPositionError"));
        return false;
      }

      if (
        positions &&
        positions.indexOf(51) !== -1 &&
        positions.indexOf(50) !== -1
      ) {
        toastHelper.error(
          this.$t("newsLiveNarrotiveAndPrivateFilePositionError")
        );
        return false;
      }

      let mainResult = this.collectMain();
      if (!mainResult) return false;

      let generalOptionsResult = this.collectGeneralOption();
      if (type == "draft") {
        this.news.isDraft = true;
      } else if (type == "publish") {
        this.news.isDraft = false;
      }
      if (!generalOptionsResult) {
        this.news.isDraft = true;
        return false;
      }

      let newsFilesResult = this.collectNewsFiles();
      if (!newsFilesResult) return false;

      let newsCategoriesResult = this.collectNewsCategories();
      if (!newsCategoriesResult) return false;

      let relatedNewsResult = this.collectRelatedNews();
      if (!relatedNewsResult) return false;

      let imageGalleryResult = this.collectImageGallery();
      if (!imageGalleryResult) return false;
      let videoGalleryResult = true;
      // if it's not video heading news
      if (positions.indexOf(74) === -1) {
        let videoGalleryResult = this.collectVideoGallery();
        if (!videoGalleryResult) return false;
      }
      this.checkJunkFiles();
      this.news.htmlContent = this.news.htmlContent
        .split(`<figure class="media"><oembed url="`)
        .join(
          `<video class="video-js" controls preload="auto" data-setup="{}" style="max-height:300px !important;"><source src="`
        );
      this.news.htmlContent = this.news.htmlContent
        .split(`"></oembed></figure>`)
        .join(`" type="video/mp4"></video>`);
      if (
        mainResult &&
        generalOptionsResult &&
        newsFilesResult &&
        newsCategoriesResult &&
        relatedNewsResult &&
        imageGalleryResult &&
        videoGalleryResult
      ) {
        let path = "News/add";
        http
          .post(path, this.news)
          .then((result) => {
            if (result && result.success) {
              if (result.data && result.data > 0) {
                if (isPreview == false) {
                  toast.success(result.message);
                  this.isAfterSave = true;
                  this.$router.push({ path: "/news-list" });
                } else {
                  const newsId = result.data;
                  let path = `News/getbyid?newsId=${this.newsId}`;
                  http.get(path).then((response) => {
                    var previewNews = JSON.parse(JSON.stringify(response));
                    if (previewNews.newsTypeEntityId === 61) {
                      window.open(previewNews.externalLink, "_blank");
                    } else
                      window.open(
                        process.env.VUE_APP_UI_URL +
                          "/" +
                          previewNews.newsCategoryList[0].category.url +
                          "/" +
                          previewNews.url +
                          "-" +
                          previewNews.historyNo,
                        "_blank"
                      );
                  });
                  // if (this.news.newsTypeEntityId === 61) {
                  //   window.open(this.news.externalLink, "_blank");
                  // } else
                  // window.open(
                  //   process.env.VUE_APP_UI_URL +
                  //     `/043fe94c-710d-4287-a337-b7c2a0d2857e/${newsId}`,
                  //   "_blank"
                  // );
                }
              } else {
                // it means news is locked by another user
                if (result.data === -1) {
                  this.lockUser = result.message;
                  this.lockUserId = 0;
                  toastHelper.error(this.$t("newsLocked"));
                } else {
                  toastHelper.error(result.message);
                }
              }
            }
          })
          .catch((err) => {
            window.console.log(err);
          });
      }
    },
    checkJunkFiles() {
      let fileIdsToDelete = [];
      if (this.news.newsTypeEntityId == 4) {
        let checkVideoFiles = this.news.newsFileList.filter(
          (p) => p.newsFileTypeEntityId == 10
        );
        if (checkVideoFiles.length > 0) {
          fileIdsToDelete = [];
          for (let i = 0; i < checkVideoFiles.length; i++) {
            fileIdsToDelete.push(checkVideoFiles[i].fileId);
            if (checkVideoFiles[i].videoCoverFileId != null) {
              fileIdsToDelete.push(checkVideoFiles[i].videoCoverFileId);
            }
          }
          this.removeJunkFiles(fileIdsToDelete);
        }
      } else if (this.news.newsTypeEntityId == 3) {
        let checkImageFiles = this.news.newsFileList.filter(
          (p) => p.newsFileTypeEntityId == 9
        );
        if (checkImageFiles.length > 0) {
          fileIdsToDelete = [];
          for (let i = 0; i < checkImageFiles.length; i++) {
            fileIdsToDelete[i] = checkImageFiles[i].fileId;
          }
          this.removeJunkFiles(fileIdsToDelete);
        }
      }
    },
    removeJunkFiles(fileIds) {
      let path = "Files/deletefilesbyid";
      http.post(path, fileIds).then((result) => {
        if (result && result.success) {
          //toast.success(result.message);
        }
      });
    },
    collectMain() {
      this.$v.form.$touch();
      let checkSeo = false;
      if (this.$v.form.seoDescription.$anyError) checkSeo = true;
      if (this.$v.form.seoTitle.$anyError) checkSeo = true;
      if (this.$v.form.socialDescription.$anyError) checkSeo = true;
      if (this.$v.form.socialTitle.$anyError) checkSeo = true;
      if (checkSeo) {
        toastHelper.error(this.$t("seoValidationError"));
        return false;
      }
      if (this.$v.form.$anyError) {
        toastHelper.error(this.$t("mainValidationError"));
        return false;
      }

      this.news = { ...this.news, ...this.form };
      this.news.userId = jwtHelper.getUserId();

      return true;
    },
    collectGeneralOption() {
      let generalSection = this.$refs.generalOptions;
      generalSection.$v.form.$touch();
      if (generalSection.$v.form.$anyError) {
        toastHelper.error(this.$t("generalOptionValidationError"));
        return false;
      }
      if (
        generalSection.form.newsTypeEntityId === 52 &&
        !generalSection.form.authorId
      ) {
        toastHelper.error(this.$t("selectAuthorTitle"));
        return false;
      }
      let generalModel = generalSection.form;
      this.news = generalHelper.copyObjects(this.news, generalModel);
      this.news.newsTagList = [];
      generalModel.tagIds.forEach((tag) => {
        this.news.newsTagList.push({
          tagId: tag.key,
        });
      });
      this.news.newsPositionList = [];
      let newsPositionIds = generalSection.$refs.newsPositions.positionIds;
      if (newsPositionIds.length > 0) {
        newsPositionIds.forEach((position, index) => {
          var ord = this.positionsList.filter((f) => f.id === position);
          this.news.newsPositionList.push({
            positionEntityId: position,
            value: true,
            order: ord == null || ord.length == 0 ? index : ord[0].order,
          });
        });
      }

      this.news.newsPropertyList = [];
      let newsPropertyIds = generalSection.$refs.newsProperties.propertyIds;
      if (newsPropertyIds.length > 0) {
        newsPropertyIds.forEach((property) => {
          this.news.newsPropertyList.push({
            propertyEntityId: property,
            value: true,
          });
        });
      }

      if (generalModel.publishDateTime == "") {
        this.news.publishDate = dateHelper.getDate("");
        this.news.publishTime = dateHelper.getTime("");
      } else {
        this.news.publishDate = dateHelper.getDate(
          generalModel.publishDateTime
        );
        this.news.publishTime = dateHelper.getTime(
          generalModel.publishDateTime
        );
      }
      if (generalSection.form.newsTypeEntityId === 52) {
        this.news.authorId = generalSection.form.authorId;
      } else {
        this.news.authorId = null;
      }
      if (!generalSection.form.pushNotification) {
        this.news.notificationTitle = "";
        this.news.notificationDescription = "";
      } else {
        this.news.notificationTitle = generalSection.form.notificationTitle;
        this.news.notificationDescription =
          generalSection.form.notificationDescription;
      }

      return true;
    },
    collectNewsFiles() {
      let checkFilesPositions = true;
      let newsFileSection = this.$refs.newsFiles;
      let fileSectionModel = newsFileSection.fileTypeEntities;
      let invalidSize = false;
      let checkNewsType = true;
      let invalidTypes = "";
      let appSettingRequiredState = true;
      let checkVideoHeadingFiles = true;
      if (fileSectionModel.length > 0) {
        let junkIds = [];
        fileSectionModel.forEach((file) => {
          if (file.isShowing) {
            let size = this.$appSetting.fileTypeEntity.filter(
              (p) => p.id == file.id
            )[0];
            if (file.onlineFile != null) {
              if (
                parseInt(size.cropSize[0]) != file.width ||
                parseInt(size.cropSize[1]) != file.height
              ) {
                invalidSize = true;
                invalidTypes = invalidTypes + ", " + file.entityName;
              }
            }
            if (file.onlineFile != null) {
              this.news.newsFileList.push({
                fileId: file.onlineFile.id,
                videoCoverFileId: null,
                newsFileTypeEntityId: file.id,
                order: 0,
                title: file.title,
                description: file.description,
              });
            }
          } else {
            if (file.onlineFile != null) {
              junkIds.push(file.onlineFile.id);
            }
          }
        });
        if (junkIds.length > 0) {
          this.removeJunkFiles(junkIds);
        }
        let bindPositionFileType = this.$appSetting.bindPositionFileType;
        //let bindFileTypePosition = this.$appSetting.bindFileTypePosition;
        // this.news.newsFileList.forEach((item) => {
        //   let checkId = bindFileTypePosition[item.newsFileTypeEntityId];
        //   if (checkId !== undefined) {
        //     let checkNews = this.news.newsPositionList.filter((p) =>
        //       checkId.includes(p.positionEntityId)
        //     );
        //     if (checkNews.length == 0) {
        //       checkFilesPositions = false;
        //     }
        //   }
        // });

        // Check if news is gallery then check thumbnail image available
        // if (
        //   this.news.newsTypeEntityId == 3 ||
        //   this.news.newsTypeEntityId == 4
        // ) {
        //   let checkFile = this.news.newsFileList.filter(
        //     (p) => p.newsFileTypeEntityId == 33
        //   ).length;
        //   if (checkFile == 0) {
        //     checkNewsType = false;
        //   }
        // }
        // Check if news is gallery then check thumbnail image available

        //Check AppSetting required files
        let requiredIds = this.$appSetting.fileTypeEntity.filter(
          (p) => p.required == 1
        );
        requiredIds.forEach((item) => {
          const checkFiles = this.news.newsFileList.filter(
            (p) => p.newsFileTypeEntityId == item.id
          );
          if (checkFiles == 0) {          
            appSettingRequiredState = false;
          }
        });
        //Check AppSetting required files
        this.news.newsPositionList.forEach((item) => {
          if (item.positionEntityId === 74) {
            // video heading news
            var isVideoValid = this.collectVideoHeading();
            if (!isVideoValid) {
              checkVideoHeadingFiles = false;
            }
          }

          let checkId = bindPositionFileType[item.positionEntityId];
          const isMainPos = this.$appSetting.MainPositions.filter(
            (p) => p.id == item.positionEntityId
          );
          const SonHaberPosId = 19;
          if (
            checkId === undefined &&
            item.positionEntityId != SonHaberPosId &&
            isMainPos.length == 0
          ) {
            // If checkId is empty so position is category and check is there any file related with Main Page or not
            const MainPageFileId = 12;
            checkId = [];
            checkId.push(MainPageFileId);
            // If checkId is empty so position is category and check is there any file related with Main Page or not
          }
          if (checkId !== undefined) {
            checkId.forEach((bindId) => {
              let checkNews = this.news.newsFileList.filter(
                (p) => p.newsFileTypeEntityId == bindId
              );
              if (checkNews.length == 0) {
                checkFilesPositions = false;
              }
            });
          }
        });
      }
      if (!checkVideoHeadingFiles) {
        toastHelper.error(this.$t("videoGalleryValidationError"));
        return false;
      }
      console.log(checkVideoHeadingFiles);
      if (invalidSize) {
        toastHelper.error(this.$t("newsFileSizeError") + invalidTypes);
        return false;
      }

      // if (!checkNewsType) {
      //   toastHelper.error(this.$t("newsTypeGalleryError"));
      //   return false;
      // }
      if (!checkFilesPositions) {
        toastHelper.error(this.$t("newsPositionError"));
        return false;
      }
      if (!appSettingRequiredState) {
        toastHelper.error(this.$t("newsFilesRequired"));
        return false;
      }
      return true;
    },
    collectNewsCategories() {
      let newsCategorySection = this.$refs.generalOptions.$refs.newsCategories;
      let selectedCategories = newsCategorySection.categoryList.filter(
        (category) => category.isChecked
      );
      if (selectedCategories.length == 0) {
        toastHelper.error(this.$t("categoryRequired"));
        return false;
      }
      if (selectedCategories.length > 0) {
        this.firstCategoryTitle = selectedCategories[0].title;
        let newCategories = [];
        selectedCategories.forEach((category) => {
          newCategories.push({
            categoryId: category.id,
          });
        });
        this.news.newsCategoryList = newCategories;
      }
      return true;
    },
    collectRelatedNews() {
      let relatedNewsSection = this.$refs.relatedNews;
      if (relatedNewsSection != null) {
        this.news.newsRelatedNewsList = [];
        let selectedRelatedNews = relatedNewsSection.newsList;
        if (selectedRelatedNews.length > 0) {
          selectedRelatedNews.forEach((relatedNews) => {
            this.news.newsRelatedNewsList.push({
              relatedNewsId: relatedNews.id,
            });
          });
        }
      }
      if (false) {
        toastHelper.error(this.$t("relatedNewsValidationError"));
      }
      return true;
    },
    collectImageGallery() {
      let imageGallerySection = this.$refs.newsImageGallery;
      let imageGalleryModel = imageGallerySection.uploadFilesList;
      let isImageValid = true;
      if (imageGalleryModel.length > 0) {
        imageGalleryModel.forEach((image, index) => {
          var fileTagIds = [];
          if (image.tagIds && image.tagIds.length) {
            image.tagIds.forEach((tg, ind) => {
              fileTagIds.push({
                tagId: tg.key,
                fileId: image.onlineFile.id,
              });
            });
          }
          if (image.onlineFile != null) {
            const validSize = this.$appSetting.fileTypeEntity.filter(
              (p) => p.id == 9
            )[0];
            if (
              validSize.cropSize[0] !== image.width ||
              validSize.cropSize[1] != image.height
            )
              isImageValid = false;
            this.news.newsFileList.push({
              fileId: image.onlineFile.id,
              videoCoverFileId: null,
              newsFileTypeEntityId: 9,
              order: index,
              title: image.title,
              description: image.description,
              validState: "index",
              fileTagList: fileTagIds,
            });
          }
        });
      }
      if (!isImageValid)
        toastHelper.error(this.$t("imageGalleryValidationError"));
      return isImageValid;
    },
    collectVideoGallery() {
      let videoGallerySection = this.$refs.newsVideoGallery;
      let videoGalleryModel = videoGallerySection.uploadFilesList;
      let isVideoValid = true;
      if (videoGalleryModel.length > 0) {
        videoGalleryModel.forEach((video, index) => {
          if (video.onlineVideoFile != null) {
            let tempCoverId = null;
            if (video.onlineCoverFile != null) {
              const validSize = this.$appSetting.fileTypeEntity.filter(
                (p) => p.id == 10
              )[0];
              if (
                validSize.cropSize[0] != video.width ||
                validSize.cropSize[1] != video.height
              )
                isVideoValid = false;
              tempCoverId = video.onlineCoverFile.id;
            } else {
              isVideoValid = false;
            }
            this.news.newsFileList.push({
              fileId: video.onlineVideoFile.id,
              videoCoverFileId: tempCoverId,
              newsFileTypeEntityId: 10,
              order: index,
              title: video.title,
              description: video.description,
              validState: "index",
            });
          }
        });
      }
      if (!isVideoValid)
        toastHelper.error(this.$t("videoGalleryValidationError"));
      return isVideoValid;
    },
    collectVideoHeading() {
      let videoGallerySection = this.$refs.newsVideoGallery;
      let videoGalleryModel = videoGallerySection.uploadFilesList;
      console.log(videoGallerySection);
      let isVideoValid = true;
      if (videoGalleryModel.length > 0) {
        videoGalleryModel.forEach((video, index) => {
          if (video.onlineVideoFile != null) {
            let tempCoverId = null;
            if (video.onlineCoverFile != null) {
              const validSize = this.$appSetting.fileTypeEntity.filter(
                (p) => p.id == 10
              )[0];
              if (
                validSize.cropSize[0] != video.width ||
                validSize.cropSize[1] != video.height
              )
                isVideoValid = false;
              tempCoverId = video.onlineCoverFile.id;
            } else {
              isVideoValid = false;
            }
            this.news.newsFileList.push({
              fileId: video.onlineVideoFile.id,
              videoCoverFileId: tempCoverId,
              newsFileTypeEntityId: 10,
              order: index,
              title: video.title,
              description: video.description,
              validState: "index",
            });
          }
        });
      } else {
        isVideoValid = false;
      }
      return isVideoValid;
    },

    collapseIcon(element) {
      element = this.$refs[element];
      let all = this.$el.getElementsByClassName("fa fa-arrow-up");
      if (all.length > 0) {
        for (let i = 0; i < all.length; i++) {
          all[i].setAttribute("class", "fa fa-arrow-down");
        }
      }
      let buttonElement = element;
      if (buttonElement.getAttribute("class").includes("not-collapsed")) {
        buttonElement.childNodes[2].childNodes[0].setAttribute(
          "class",
          "fa fa-arrow-down"
        );
      } else {
        buttonElement.childNodes[2].childNodes[0].setAttribute(
          "class",
          "fa fa-arrow-up"
        );
      }

      let isRelatedNews = buttonElement.getAttribute("data-isRelatedNews");
      if (isRelatedNews != null) {
        this.loadRelatedNews = true;
      }
    },
    onTitleLostFocus() {
      this.newsId = this.$route.params.id ? this.$route.params.id : 0;
      this.newsId = parseInt(this.newsId);
      if (this.newsId <= 0) {
        this.$refs.generalOptions.form.url = this.title;
        this.$refs.generalOptions.form.notificationDescription = this.title;
      }
    },
    generalOptionsChanged(generalModel) {
      this.generalOptionsModel = generalModel;
    },

    getNewsInformation(newsId) {
      let path = `News/getbyid?newsId=${this.newsId}`;
      http
        .get(path)
        .then((response) => {
          this.news = JSON.parse(JSON.stringify(response));
          this.lockUser = this.news.lockUser;
          this.lockUserId = this.news.lockUserId;
          // this.news.newsFileList = this.news.newsFileList;
          //  this.news.newsCategoryList = this.news.newsCategoryList;
          // this.news.newsPositionList = this.news.newsPositionList;
          this.news.newsPositionOrder = this.getNewsPositionOrder();
          // this.news.newsPropertyList = this.news.newsPropertyList;
          // this.news.newsRelatedNewsList = this.news.newsRelatedNewsList;
          // this.news.newsTagList = this.news.newsTagList;
          this.news.htmlContent = this.news.htmlContent
            .split(
              `<video class="video-js" controls preload="auto" data-setup="{}" style="max-height:300px !important;"><source src="`
            )
            .join(`<figure class="media"><oembed url="`);
          this.news.htmlContent = this.news.htmlContent
            .split(`" type="video/mp4"></video>`)
            .join(`"></oembed></figure>`);

          this.form = generalHelper.copyObjects(this.form, this.news);
          this.keyWords = this.news.seoKeywords
            ? this.news.seoKeywords.split(",")
            : null;
          this.news.newsId = parseInt(this.newsId);
          //  this.setGeneralOptions();

          // this.setNewsFiles();
          // this.setImageGallery();
          // this.setVideoGallery();
        })
        .catch((e) => {
          console.log(e);
          this.newsLocked = true;
          this.$router.push({ path: "/news-list" });
        })
        .finally(() => {
          this.isInitializing = false;
          this.$nextTick(function () {
            this.setGeneralOptions();
            this.setNewsFiles();
            this.setImageGallery();
            this.setVideoGallery();
          });
        });
    },
    setGeneralOptions() {
      let generalSection = this.$refs.generalOptions;
      generalSection.form = generalHelper.copyObjects(
        generalSection.form,
        this.news
      );
      generalSection.form.order = this.news.newsPositionOrder;
      this.news.newsTagList.forEach((tag) => {
        generalSection.loadTags.push({
          key: tag.tagId,
          value: tag.tagName,
        });
        generalSection.form.tagIds.push(tag.tagId);
      });

      // let positions = generalSection.$refs.newsPositions;
      // for (let i = 0; i < positions.positionsList.length; i++) {
      //   let check = this.news.newsPositionList.filter(
      //     (p) => p.positionEntityId == positions.positionsList[i].id
      //   );
      //   if (check.length != 0) {
      //     positions.positionsList[i].isChecked = true;
      //   }
      // }

      let property = this.$refs.generalOptions.$refs.newsProperties;
      for (let i = 0; i < property.propertiesList.length; i++) {
        let check = this.news.newsPropertyList.filter(
          (p) => p.propertyEntityId == property.propertiesList[i].id
        );
        if (check.length != 0) {
          property.propertiesList[i].isChecked = true;
        } else {
          property.propertiesList[i].isChecked = false;
        }
      }
      generalSection.form.publishDateTime = dateHelper.getPublishDateTime(
        this.news.publishDate,
        this.news.publishTime
      );
      generalSection.form.notificationDescription = this.news.title;
    },
    setNewsFiles() {
      let newsFiles = this.$refs.newsFiles.fileTypeEntities;
      for (let i = 0; i < newsFiles.length; i++) {
        let item = this.news.newsFileList.filter(
          (p) => p.newsFileTypeEntityId == newsFiles[i].id
        );
        if (item.length > 0) {
          item = item[0];
          let updateModel = {
            title: item.title,
            description: item.description,
            id: item.newsFileTypeEntityId,
            localFile: null,
            onlineFile: {
              id: item.fileId,
              fileName: item.fileName,
              newsFileTypeEntityId: item.newsFileTypeEntityId,
            },
            uploadPercentage: 0,
            height: 0,
            width: 0,
            imageIcon: "fa fa-question-circle text-primary",
          };
          this.$refs.newsFiles.fileTypeEntities[i].title = updateModel.title;
          this.$refs.newsFiles.fileTypeEntities[i].description =
            updateModel.description;
          this.$refs.newsFiles.fileTypeEntities[i].id = updateModel.id;
          this.$refs.newsFiles.fileTypeEntities[i].localFile =
            updateModel.localFile;
          this.$refs.newsFiles.fileTypeEntities[i].onlineFile =
            updateModel.onlineFile;
          this.$refs.newsFiles.fileTypeEntities[i].uploadPercentage =
            updateModel.uploadPercentage;
        }
      }
      this.$refs.newsFiles.calculateImageSize(null);
    },
    setImageGallery() {
      let imageGallerySection = this.$refs.newsImageGallery.uploadFilesList;
      let newsGalleryModel = this.news.newsFileList.filter(
        (p) => p.newsFileTypeEntityId == 9
      );
      if (newsGalleryModel.length > 0) {
        newsGalleryModel.forEach((file) => {
          var fileLoadTags = [],
            fileTagIds = [];
          file.fileTagList.forEach((tag) => {
            fileLoadTags.push({
              key: tag.tagId,
              value: tag.tagName,
            });
            fileTagIds.push(tag.tagId);
          });
          let tempModel = {
            title: file.title,
            description: file.description,
            localFile: null,
            onlineFile: { id: file.fileId, fileName: file.fileName },
            uploadPercentage: 0,
            validState: "index",
            fileTagList: file.fileTagList,
            loadTags: fileLoadTags,
            tagIds: fileTagIds,
          };
          imageGallerySection.push(tempModel);
        });
        this.$refs.newsImageGallery.calculateImageSize(null);
      }
    },
    setVideoGallery() {
      let videoGallerySection = this.$refs.newsVideoGallery.uploadFilesList;
      let newsGalleryModel = this.news.newsFileList.filter(
        (p) => p.newsFileTypeEntityId == 10
      );
      if (newsGalleryModel.length > 0) {
        newsGalleryModel.forEach((file) => {
          let tempModel = {};
          if (file.videoCoverFileId != null) {
            tempModel = {
              title: file.title,
              description: file.description,
              localCoverFile: null,
              localVideoFile: null,
              onlineVideoFile: {
                id: file.fileId,
                fileName: file.fileName,
                shortFileName: this.$t("downloadVideo"),
              },
              onlineCoverFile: {
                id: file.videoCoverFileId,
                fileName: file.coverFileName,
              },
              height: 0,
              width: 0,
              uploadVideoPercentage: 0,
              uploadCoverPercentage: 0,
              validState: "index",
            };
          } else {
            tempModel = {
              title: file.title,
              description: file.description,
              localCoverFile: null,
              localVideoFile: null,
              onlineVideoFile: {
                id: file.fileId,
                fileName: file.fileName,
                shortFileName: this.$t("downloadVideo"),
              },
              onlineCoverFile: null,
              uploadVideoPercentage: 0,
              uploadCoverPercentage: 0,
              validState: "index",
            };
          }
          videoGallerySection.push(tempModel);
        });
        this.$refs.newsVideoGallery.calculateImageSize(null);
      }
    },
  },
  computed: {
    title() {
      return this.form.title;
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getFileTypeEntities();
    });
  },
  watch: {
    newsId() {},
    keyWords() {
      let keys = "";
      if (this.keyWords != null) {
        this.keyWords.forEach((element) => {
          keys = keys + "," + element;
        });
        this.news.seoKeywords = keys.substr(1);
        this.form.seoKeywords = keys.substr(1);
      }
    },
  },
};
</script>
<style scoped>
.ck-content {
  max-height: 800px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.draft-button {
  border-radius: 0px;
  background-color: #555555;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.preview-button {
  border-radius: 0px;
}
.publish-button {
  border-radius: 0px;
  background-color: #4caf50;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.lock-user {
  display: inline-block;
  background: white;
  width: 100%;
  height: 43px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}
</style>