<template>
  <b-container>
    <div class="animated fadeIn">
      <b-form @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col sm="12">
            <v-client-table
              :columns="columns"
              :data="propertyList"
              :options="options"
              id="dataTable"
              ref="dataTable"
              class="dataGridRowHeight"
            >
              <template slot="active" slot-scope="props">
                <CheckBox
                  v-model="props.row.isChecked"
                  :defaultValue="props.row.isChecked"
                  @input="(val) => changed(props.row)"
                ></CheckBox>
              </template>
            </v-client-table>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
import CheckBox from "../../widgets/form/CustomCheckBox";
import gridOptions from "../../shared/grid-options";
import { ClientTable } from "vue-tables-2";
import jwtHelper from "../../shared/jwt-helper";
export default {
  name: "PropertyList",
  props: {},
  components: {
    CheckBox,
    ClientTable,
  },
  methods: {
    changed(val) {
      let itemIndex = this.propertyList.findIndex((x) => x.id == val.id);
      this.propertyList[itemIndex].isChecked = val.isChecked;
    },
    isChanged() {
      for (let i = 0; i < this.propertyList.length; i++) {
        if (this.propertyList[i].isChecked != this.orgList[i].isChecked) {
          return true;
          break;
        }
      }
      return false;
    },
    loadData() {
      this.propertyList = [];
      this.getProperties();
    },
    getProperties() {
      let path = `Entities/getpropertyentities`;
      this.currentProperies = [];
      http.get(path).then((response) => {
        if (response) {
          this.currentProperies = response;
          this.currentProperies.forEach((element) => {
            let isChecked = false;
            let temp = {
              id: element.id,
              title: element.entityName,
              isChecked: isChecked,
            };
            this.propertyList.push(temp);
            this.orgList.push(JSON.parse(JSON.stringify(temp)));
          });
          this.$refs.dataTable.resetQuery();
        }
      });
    },
  },
  data() {
    return {
      propertyList: [],
      currentProperies: [],
      orgList: [],
      userId: jwtHelper.getUserId(),
      columns: ["title", "active"],
      options: {
        ...gridOptions.getAll(),
        orderBy: {
          column: "id",
          ascending: false,
        },
        debounce: 100,
        headings: {
          title: this.$t("title"),
          active: this.$t("active"),
        },
      },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    userId() {},
  },
};
</script>
<style scoped>
fieldset.form-group {
  margin-bottom: 0rem !important;
}
</style>