<template>
  <b-row>
    <b-col sm="12">
      <b-form-group :label="$t('date')" label-for="filterDate">
        <b-input-group>
          <b-form-datepicker
            id="filterDate"
            v-model="filterDate"
            @context="onSelect"
            :locale="local"
          ></b-form-datepicker>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import dateHelper from "../../shared/date-helper";

export default {
  name: "CustomDatePicker",
  props: {
    date: {
      default: null,
      type: String,
      required: false,
    },
  },
  created() {
    this.filterDate =
      this.from ??
      dateHelper.toInputDateFormat(dateHelper.addYear(new Date(), -1));
    this.local = localStorage.lang;
  },
  methods: {
    onSelect() {
      if (this.filterDate) {
        this.$emit("change", {
          date: this.filterDate,
        });
      } else {
        this.$emit("change", null);
      }
    },
  },
  data() {
    return {
      local: "tr",
      filterDate: null,
      filterToDate: null,
    };
  },
};
</script>
