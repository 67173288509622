<template>
  <div class="text-right">
    <b-spinner
      small
      variant="success"
      v-show="isInLoading"
      class="tag-loading"
    ></b-spinner>
    <tags-input
      element-id="tags"
      class="text-left"
      v-model="selectedTags"
      :existing-tags="dataList"
      :typeahead="true"
      add-tags-on-comma
      :placeholder="$t('tags')"
      :discard-search-text="$t('searchResult')"
      @keydown="debouncedQuery"
      @tag-added="(tag) => tagAdded(tag)"
    ></tags-input>
    <b-form-invalid-feedback
      :state="validateState(id)"
      :id="id + '-feedback'"
      class="text-left"
      >{{ $t(`${prename}Validator.${id}`) }}</b-form-invalid-feedback
    >
  </div>
</template>
<script>
import http from "../../shared/http";
import stringHelper from "../../shared/string-helper";
import toast from "../../shared/toast-helper";
export default {
  name: "CustomTagInput",
  props: {
    defaultValues: { type: Array },
    id: { required: false, type: String, default: "tagIds" },
    prename: { required: false, type: String, default: "news" },
    form: { required: true, type: Object },
  },
  data() {
    return {
      dataList: [],
      debounce: 800,
      debounceFunction: null,
      isInLoading: false,
      selectedTags: null,
    };
  },
  watch: {
    defaultValues() {
      this.selectedTags = this.defaultValues;
    },
  },
  methods: {
    debouncedQuery(event) {
      clearTimeout(this.debounceFunction);
      this.debounceFunction = setTimeout(() => {
        this.inputChanged(event.target.value);
      }, this.debounce);
    },
    validateState(name) {
      const { $dirty, $error } = this.form;
      return $dirty ? !$error : null;
    },
    inputChanged(val) {
      if (val.length > 2) {
        this.getDataList(val);
      }
    },
    tagAdded(tag) {
      if (tag.key == "") {
        this.insertNewTag(tag);
      } else {
        this.$emit("changed", this.selectedTags);
      }
    },
    getDataList(query) {
      let path = `Tags/searchbytagname?tagName=${query}`;
      this.isInLoading = true;
      http.get(path, null, false).then((response) => {
        this.isInLoading = false;
        if (response) {
          var data = response;
          this.dataList = [];
          data.forEach((element) => {
            this.dataList.push({
              key: element.id,
              value: element.tagName,
            });
          });
        }
      });
    },
    insertNewTag(tag) {
      let model = {
        id: 0,
        tagName: tag.value,
        url: stringHelper.standardUrl(tag.value),
        active: true,
      };
      let path = "tags/add";
      http.post(path, model).then((result) => {
        if (result && result.success) {
          let currentIndex = this.selectedTags.indexOf(tag);
          this.selectedTags[currentIndex].key = parseInt(result.data);
          this.$emit("changed", this.selectedTags);
        }
      });
    },
  },
};
</script>
<style scoped>
.tag-loading {
  position: fixed;
  margin-top: 12px;
  margin-left: -23px;
  z-index: 1000;
}
</style>