import { required, minLength, maxLength } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            title: {
            },
            description: {
            }
        },
    }
}

export default { getValidations };