<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      @hide="editModal = false"
      :fileModel="selectedFile"
      :validHeight="editHeight"
      :validWidth="editWidth"
      @onSave="(base64) => saveAndUpload(base64)"
    >
    </PhotoEditorModal>
    <b-row>
      <input
        type="file"
        class="d-none"
        ref="uploadVideoFile"
        multiple
        accept="video/*"
        v-on:change="() => videoFileLoaded(0)"
      />
      <draggable v-model="uploadFilesList">
        <b-col
          sm="4"
          class="imageCard"
          v-bind:key="fileType.id"
          v-for="(fileType, index) in uploadFilesList"
        >
          <b-row>
            <b-col sm="12">
              <div class="card bg-light text-dark">
                <label :class="fileType.validState"
                  >{{ fileType.order }} ({{ validSize[0] }}x{{
                    validSize[1]
                  }})</label
                >
                <img
                  class="card-img-top"
                  :src="
                    fileType.localCoverFile != null
                      ? fileType.localCoverFile
                      : fileType.onlineCoverFile.fileName
                  "
                  @click="showPreview(fileType)"
                  v-if="
                    fileType.onlineVideoFile == null ||
                    fileType.onlineCoverFile != null
                  "
                />

                <video
                  class="card-img-top"
                  controlsList="nodownload"
                  preload="metadata"
                  :src="
                    fileType.localVideoFile != null
                      ? fileType.localVideoFile
                      : fileType.onlineVideoFile.fileName
                  "
                  v-if="
                    fileType.onlineVideoFile != null &&
                    fileType.onlineCoverFile == null
                  "
                />
                <div class="card-body">
                  <input
                    type="file"
                    class="d-none"
                    ref="uploadCoverFile"
                    accept="image/*"
                    v-on:change="() => coverFileLoaded(index)"
                  />
                  <div class="card-title">
                    <NormalTextBox
                      prename="files"
                      id="title"
                      icon="fa fa-font"
                      :withLabel="false"
                      v-model="fileType.title"
                      :value="fileType.title"
                    />
                  </div>
                  <div class="card-text text-center">
                    <NormalTextArea
                      prename="files"
                      id="description"
                      :rows="2"
                      :withLabel="false"
                      v-model="fileType.description"
                      :value="fileType.description"
                    />

                    <b-link
                      :href="fileType.onlineVideoFile.fileName"
                      target="_blank"
                      v-if="fileType.onlineVideoFile != null"
                    >
                      {{ fileType.onlineVideoFile.shortFileName }}
                    </b-link>
                    <hr v-if="fileType.onlineVideoFile != null" />

                    <div class="btn-group" role="group">
                      <b-button
                        type="button"
                        size="sm"
                        class="text-center"
                        @click="remove(index)"
                        variant="danger"
                      >
                        <i class="fa fa-remove"></i>{{ $t("delete") }}
                      </b-button>
                      <b-button
                        v-if="fileType.onlineCoverFile == null"
                        type="button"
                        size="sm"
                        class="text-center"
                        @click="browseCover(index)"
                        variant="success"
                        ><i class="fa fa-upload"></i>
                        {{ $t("uploadCover") }}
                      </b-button>

                      <b-button
                        v-if="fileType.onlineCoverFile != null"
                        type="button"
                        size="sm"
                        class="text-center"
                        @click="removeCover(index)"
                        variant="info"
                        ><i class="fa fa-remove"></i>
                        {{ $t("removeCover") }}
                      </b-button>
                    </div>
                    <!-- <b-link
                      size="sm"
                      class="text-center"
                      @click="remove(index)"
                      variant="danger"
                    >
                      <i class="fa fa-remove"></i> {{ $t("delete") }}
                    </b-link> -->

                    <b-progress
                      :max="100"
                      height="1rem"
                      animated
                      style="margin: 2px"
                      v-if="fileType.uploadVideoPercentage > 0"
                    >
                      <b-progress-bar
                        :value="fileType.uploadVideoPercentage"
                        variant="success"
                      >
                        <span
                          >{{ $t("video") }}:
                          <strong
                            >{{
                              fileType.uploadVideoPercentage.toFixed(0)
                            }}%</strong
                          ></span
                        >
                      </b-progress-bar>
                    </b-progress>

                    <b-progress
                      :max="100"
                      height="1rem"
                      animated
                      style="margin: 2px"
                      v-if="fileType.uploadCoverPercentage > 0"
                    >
                      <b-progress-bar
                        :value="fileType.uploadCoverPercentage"
                        variant="success"
                      >
                        <span
                          >{{ $t("cover") }}:
                          <strong
                            >{{
                              fileType.uploadCoverPercentage.toFixed(0)
                            }}%</strong
                          ></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </draggable>

      <b-col sm="12" class="float-right text-center">
        <b-button @click="browse(0)" class="btn btn-primary btn-lg mh-100">
          <i class="icon-cloud-upload h1" style="margin: 0px" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NormalTextBox from "../../widgets/form/NormalTextBox";
import NormalTextArea from "../../widgets/form/NormalTextArea";
import CustomModal from "../../widgets/form/CustomModal";
import { validationMixin } from "vuelidate";
import filesValidator from "../../validations/files-validator";
import toastHelper from "../../shared/toast-helper";
import { http } from "../../shared/http-base";
import httpGeneral from "../../shared/http";
import draggable from "vuedraggable";
import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
import { locales } from "moment";
import generalHelper from "../../shared/utils/general-helper";
import generatorsHelper from "../../shared/utils/generators-helper";
export default {
  name: "NewsVideoGallery",
  components: {
    NormalTextBox,
    NormalTextArea,
    CustomModal,
    draggable,
    PhotoEditorModal,
  },
  data() {
    return {
      form: {
        title: "",
        description: "",
      },
      selectedFile: {},
      editModal: false,
      validSize: this.$appSetting.fileTypeEntity.filter((p) => p.id == 10)[0]
        .cropSize,
      editHeight: 0,
      editWidth: 0,
      uploadFilesList: [],
    };
  },
  watch: {
    uploadFilesList: {
      handler() {
        for (let i = 0; i < this.uploadFilesList.length; i++) {
          this.uploadFilesList[i].order = i + 1;
        }
      },
      deep: true,
    },
  },
  methods: {
    showPreview(file) {
      if (file.onlineCoverFile != null) {
        const validSize = this.validSize;
        this.editHeight = validSize[1];
        this.editWidth = validSize[0];
        this.selectedFile = null;
        this.selectedFile = file;
        this.editModal = false;
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploaded"));
      }
    },
    saveAndUpload(base64) {
      this.editModal = false;
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      let index = this.uploadFilesList.indexOf(this.selectedFile);
      this.uploadFilesList[index].localCoverFile = base64;
      this.removeFile(index);
      this.startCoverUpload(index, blob);
    },
    addNewFile() {
      this.uploadFilesList.push({
        title: "",
        description: "",
        localCoverFile: "/img/nopic.png",
        localVideoFile: "",
        onlineVideoFile: null,
        onlineCoverFile: null,
        uploadVideoPercentage: 0,
        uploadCoverPercentage: 0,
        validState: "index",
      });
    },
    browse(index) {
      this.$refs.uploadVideoFile.click();
    },
    browseCover(index) {
      this.$refs.uploadCoverFile[index].click();
    },
    remove(index) {
      let obj = this.uploadFilesList[index];
      if (obj.onlineVideoFile != null) {
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineVideoFile.id}`)
          .then((response) => {
            toastHelper.success(response.message);
          });
      }
      this.removeCover(index);
      this.uploadFilesList.splice(index, 1);
    },
    removeCover(index) {
      let obj = this.uploadFilesList[index];
      if (obj.onlineCoverFile != null) {
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineCoverFile.id}`)
          .then((response) => {
            toastHelper.success(response.message);
          });
      }
      this.uploadFilesList[index].onlineCoverFile = null;
      this.uploadFilesList[index].localCoverFile = "/img/nopic.png";
    },
    removeFile(index) {
      let obj = this.uploadFilesList[index];
      if (obj.onlineFile != null) {
        let model = {
          fileId: obj.onlineFile.id,
        };
        httpGeneral
          .post(`Files/deletefilebyid?fileId=${obj.onlineFile.id}`)
          .then((response) => {
            toastHelper.success(response.message);
          });
      }
    },
    coverFileLoaded(index) {
      if (this.$refs.uploadCoverFile[index].files.length > 0) {
        if (
          generalHelper.isImageFile(this.$refs.uploadCoverFile[index].files[0])
        ) {
          let file = this.$refs.uploadCoverFile[index].files[0];
          this.uploadFilesList[index].localCoverFile = URL.createObjectURL(
            file
          );
          this.startCoverUpload(index, file);
        } else {
          toastHelper.error(this.$t("fileIsNotValid"));
        }
      }
    },
    videoFileLoaded(index) {
      if (this.$refs.uploadVideoFile.files.length > 0) {
        let files = this.$refs.uploadVideoFile.files;
        let startIndex = 0;
        //control is there any previus files
        if (this.uploadFilesList.length > 0) {
          startIndex = this.uploadFilesList.length;
        }
        let isFirstUploaded = false;
        let file = files[0];
        if (generalHelper.isVideoFile(files[0])) {
          this.addNewFile();
          this.uploadFilesList[startIndex].uploadVideoPercentage = 1;
          this.uploadFilesList[startIndex].localVideoFile = URL.createObjectURL(
            file
          );
          this.calculateImageSize(startIndex);
          this.startUpload(startIndex, file);
          isFirstUploaded = true;
        } else {
          toastHelper.error(this.$t("fileIsNotValid"));
        }

        if (files.length > 1) {
          for (let i = 1; i < files.length; i++) {
            if (generalHelper.isVideoFile(files[i])) {
              if (isFirstUploaded) startIndex++;
              isFirstUploaded = true;
              this.addNewFile();
              let lastIndex = this.uploadFilesList.length - 1;
              file = files[i];
              this.uploadFilesList[lastIndex].uploadVideoPercentage = 1;
              this.uploadFilesList[
                lastIndex
              ].localVideoFile = URL.createObjectURL(file);
              var result = this.startUpload(startIndex, file);
            } else {
              toastHelper.error(this.$t("fileIsNotValid"));
            }
          }
        }
      }
    },
    startUpload(index, fileInput) {
      let file = this.uploadFilesList[index];
      if (file.localVideoFile != "") {
        let formData = new FormData();
        formData.append("file", fileInput);
        let self = this;
        http
          .post(process.env.VUE_APP_BASE_URL + "/api/Files/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadFilesList[index].uploadVideoPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            file.onlineVideoFile = response.data.data;
            let fileName = file.onlineVideoFile.fileName;
            fileName = fileName.split("").reverse().join("");
            fileName = fileName.substr(0, fileName.indexOf(".") + 3);
            fileName = fileName.split("").reverse().join("");
            file.onlineVideoFile.shortFileName = this.$t("downloadVideo");
            file.uploadVideoPercentage = 0;
          })
          .catch((e) => {
            self.removeCover(index);
            self.removeFile(index);
            if (!e.response.data.success) {
              toastHelper.error(e.response.data.message);
            }
          });
        this.uploadFilesList[index] = file;
      } else {
        toastHelper.error($t("fileNotSelected"));
      }
    },
    startCoverUpload(index, fileInput) {
      let file = this.uploadFilesList[index];
      this.calculateImageSize(index);
      if (file.localCoverFile != "") {
        let formData = new FormData();
        if (fileInput.fileName === undefined) {
          formData.append("file", fileInput, "image.jpg");
        } else {
          formData.append("file", fileInput);
        }

        let self = this;
        http
          .post("/Files/upload", formData, {
            onUploadProgress: function (progressEvent) {
              this.uploadFilesList[index].uploadCoverPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            file.onlineCoverFile = response.data.data;
            file.uploadCoverPercentage = 0;
          })
          .catch((e) => {
            self.removeCover(index);
            if (!e.response.data.success) {
              file.uploadCoverPercentage = 0;
              toastHelper.error(e.response.data.message);
            }
          });
        this.uploadFilesList[index] = file;
      } else {
        toastHelper.error($t("fileNotSelected"));
      }
    },
    isImageValid(file) {
      let validSize = this.validSize;
      if (validSize) {
        if (validSize[1] == file.height && validSize[0] == file.width)
          return true;
        else return false;
      } else {
        return true;
      }
    },
    loadSize(index) {
      if (
        this.uploadFilesList[index].localCoverFile != null ||
        this.uploadFilesList[index].onlineCoverFile != null
      ) {
        let img = new Image();
        img.onload = () => {
          this.uploadFilesList[index].height = img.height;
          this.uploadFilesList[index].width = img.width;
          let imageIconColor = "bg-danger";
          if (this.isImageValid(this.uploadFilesList[index])) {
            imageIconColor = "bg-success";
          }
          this.uploadFilesList[index].validState = "index " + imageIconColor;
        };
        img.src =
          this.uploadFilesList[index].localCoverFile == null
            ? this.uploadFilesList[index].onlineCoverFile.fileName
            : this.uploadFilesList[index].localCoverFile;
      } else {
        this.uploadFilesList[index].validState = "index bg-danger";
      }
    },
    calculateImageSize(index = null) {
      if (this.uploadFilesList === undefined) return;
      if (index != null) {
        //Load image size
        this.loadSize(index);
        //Load image size
      } else {
        for (let i = 0; i < this.uploadFilesList.length; i++) {
          //Load image size
          this.loadSize(i);
          //Load image size
        }
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-top: 8px;
  padding-bottom: 0px;
}
.prog {
  width: 100%;
}
.previewimage {
  cursor: pointer;
}
.animated .fadeIn .row div:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card-body {
  padding-left: 1px;
  padding-right: 1px;
}
.card-title fieldset {
  width: 100%;
  margin-bottom: 0;
}
.card-title {
  width: 100%;
  margin-bottom: 5;
}
.card-text {
  width: 100%;
}
.card-text fieldset {
  margin-bottom: 5px;
}
hr {
  margin-top: 0px;
  margin-bottom: 3px;
}
.index {
  background-color: white;
  border-radius: 5px;
  height: 30px;
  width: 90px;
  position: absolute;
  top: 5px;
  left: 5px;
  text-align: left;
  padding-top: 3px;
  padding-left: 7px;
  border-color: black;
  border: 1px;
  border-style: solid;
}
.card-img-top {
  width: 100%;
  min-width: revert;
  height: 15vw;
  object-fit: contain;
  cursor: pointer;
}
.imageCard {
  min-width: 15vw;
}
</style>