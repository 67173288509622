<template>
  <multiselect
    :options="list"
    :multiple="isMultiple"
    :label="displayField"
    :track-by="valueField"
    @input="onSelect"
    v-model="selected"
    :placeholder="placeholder"
    :showLabels="false"
  >
    <template slot="noResult"> {{ $t("dataNotFound") }} </template></multiselect
  >
</template>

<script>
import dateHelper from "../../shared/date-helper";
import http from "../../shared/http";
import { getTranslation } from "../../shared/language-helper";
export default {
  name: "NewsHistoryDropDown",
  props: {
    displayField: { type: String, required: true },
    isMultiple: { type: Boolean, default: false },
    valueField: { type: String, required: true },
    placeholder: {
      default: "",
      type: String,
      required: false,
    },
    apiSource: { type: String, required: true },
    selectedValue: { required: true },
    includeall: { required: false, default: false },
    firstSelected: { type: Boolean, default: false },
    currentNewsId: { type: Number, required: false },
  },
  data() {
    return {
      list: [],
      selected: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("change", item);
      } else {
        this.$emit("change", null);
      }
    },
    getList() {
      let path = this.apiSource;
      http.get(path).then((response) => {
        if (response) {
          var data = response;
          if (this.includeall) {
            data.unshift({
              newsId: null,
              title: this.$t("all"),
            });
          }
          for (let i = 0; i < data.length; i++) {
            data[i].createdAt = dateHelper.getDateInFormat(
              "dd/mm/yyyy hh:mm",
              data[i].createdAt
            );
            data[i].createdAt = data[i].createdAt + ": " + data[i].userName;
          }
          this.list = data.filter((p) => p.newsId != this.currentNewsId);
          this.selected = this.list.find((i) => i.newsId == null);
          this.setSelected();

          if (this.firstSelected) {
            this.selected = this.list[0];
            this.$emit("change", this.selected);
          }
          this.$emit("loaded", this.list.length);
        }
      });
    },
    setSelected() {
      this.selected =
        this.list.find((i) => i.newsId == this.selectedValue) || null;
    },
  },
  watch: {
    selectedValue() {
      this.setSelected();
    },
  },
};
</script>