import { required, minLength, maxLength } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(160),
            },
            innerTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            subTitle: {
                //maxLength: maxLength(50),
            },
            shortDescription: {
                minLength: minLength(0),
                maxLength: maxLength(500),
            },
            socialTitle: {
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            socialDescription: {
            },
            seoTitle: {
                minLength: minLength(2),
                maxLength: maxLength(500),
            },
            seoKeywords: {

            },
            seoDescription: {
                minLength: minLength(2),
                maxLength: maxLength(250),
            },

        },
    }
}

export default { getValidations };
